@import "../settings";
.pagination {
  .current {
    border-radius: 50%;
    text-align: center;
    float: none;
  }
  .page-numbers {
    transition: background-color 0.3s ease;
    margin: 0 0.25rem;
    font-weight: 400;
    min-width: 40px;
    min-height: 40px;
    text-align: center;
    line-height: 32px;
    font-size: 16px;
    border-radius: 20px;
    &:hover:not(.dots):not(.current) {
      background: $grey300;
    }
  }
  .prev,
  .next {
    padding-top: 0.625rem;
  }
}
