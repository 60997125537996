@import "../settings";

form {
  &:not(.search-form) {
    label {
      font-weight: 400;
      font-size: 1rem;
      line-height: rem-calc(24);
      color: $coal2;
      margin-bottom: 0.5rem;
    }
    input[type="text"],
    input[type="email"],
    input[type="phone"],
    input[type="phoneNumber"],
    select {
      font-weight: 400;
      background-color: $white;
      border-radius: 0.5rem;
      box-shadow: none;
      border: 1px solid $grey400;
      height: rem-calc(48);

      &:hover {
        border: 1px solid $grey500;
      }
      &:focus {
        border: 1px solid $grey800;
      }
    }
    textarea {
      border: 1px solid $grey400;
      background-color: $white;
      resize: vertical;
      &:hover {
        border: 1px solid $grey500;
      }
      &:focus {
        border: 1px solid $grey800;
      }
    }

    select {
      background-image: url("../images/chevron-down.svg");
      background-size: 14px;
      background-position: right 0 center;
      color: $primary !important;
      cursor: pointer;
    }
    input::-webkit-input-placeholder {
      visibility: hidden;
    }
    input:-moz-placeholder {
      visibility: hidden;
    }
    input::-moz-placeholder {
      color: $grey600;
      opacity: 1;
      visibility: hidden;
    }
    input:-ms-input-placeholder {
      visibility: hidden;
    }
    input {
      visibility: visible;
    }
    .invalid::before {
      content: url("../images/icons/circle-exclamation-red.svg");
      position: absolute;
      bottom: 1.5rem;
      left: 0.25rem;
    }
    .invalid::after {
      content: "This field is required";
      background-color: $white;
      border-top: none;
      border-radius: 0 0 0.25rem 0.25rem;
      width: 100%;
      padding: 0.5rem 1rem 0.5rem 1.5rem;
      display: block;
      border-top: none;
      font-size: 14px;
      line-height: 1rem;
      margin-bottom: 1rem;
      font-weight: 400;
      color: $red600;
    }
    @-moz-document url-prefix() {
      .checkbox {
        display: flex;
        flex-flow: row;
      }
      .email {
        label {
          display: none;
        }
      }
      .invalid {
        select,
        input {
          margin-bottom: 0 !important;
          border: 1px solid $red600;
        }
        select::placeholder,
        input::placeholder {
          color: $red600;
        }
      }
      input:not([type="checkbox"]) + label {
        display: none !important;
      }
    }
  }

  &.search-form.v1 {
    input {
      background-color: $white;
      &[type="search"] {
        height: 40px;
      }
    }
  }
}

form.search-form.v2,
.search-form.v2 .searchwp-form {
  width: 415px;
  max-width: 100%;
  position: relative;
  margin: 0 auto;
  input[type="search"] {
    height: 60px;
    padding: 10px 56px 10px 30px;
    border-radius: 30px;
    border: 1px solid $grey700;
    max-width: 100%;
    background-image: none !important;
    &::placeholder {
      color: $grey700 !important;
      font-size: 16px !important;
      visibility: visible !important;
      opacity: 0.5 !important;
    }
  }
  input[type="submit"] {
    position: absolute;
    top: 0;
    right: 4px;
    bottom: 0;
    margin: auto 0.5rem auto;
    border: none;
    color: transparent !important;
    background-image: url("../images/icons/button-icons/arrow-right.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 16px 14px;
    img {
      width: 20px;
    }
  }
  .search-submit {
    transition: box-shadow 0.3s ease, color 0.3s ease;
    background-color: $grey700;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    cursor: pointer;
    box-shadow: $shadowbutton;
    &:hover {
      color: $green800;

      &:not(:disabled) {
        &:hover {
          box-shadow: $shadowbuttonhover;
        }
      }
    }
    svg,
    svg path {
      fill: $white;
    }
  }
}

.form__modal {
  max-width: 600px;
  box-shadow: 0px 10px 20px rgba(147, 147, 147, 0.15);
  border-radius: 24px;
  padding: 16px 16px 32px;

  .form__modal-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    width: 4rem;
    margin: 3.5rem auto 2rem auto;
  }
  .close-button {
    right: 1rem !important;
    top: 1rem !important;
  }
  p {
    margin-bottom: 0;
  }
  .form__modal-title {
    margin-bottom: 1rem;
  }
}

.component__iterable-form {
  &:not(.as__block) {
    margin-top: 2rem;
  }
  &.on__page {
    .component__iterable-form {
      max-width: 1000px;
    }
  }
  &.as__block {
    .form__field-row:has(.country__code-container) {
      .country__code-flag img {
        border-radius: 0 !important;
      }
    }
    &.blog__form {
      margin: 2rem auto;
      padding: 1.5rem;
      box-shadow: 0px 4px 8px rgba(147, 147, 147, 0.1);
      border-radius: 8px;
      .element__section--titles {
        padding: 0;
        .element__section--title {
          margin-top: 0;
        }
      }
      .form__field-row:has(input[type="button"]) {
        @include breakpoint(medium up) {
          width: calc(50% - 0.75rem);
        }
      }
      @include breakpoint(small only) {
        .horizontal {
          margin: 1.5rem auto;
          .form__field-row {
            &:nth-child(1) {
              order: 1;
            }
            &:nth-child(2) {
              order: 5;
            }
            &:nth-child(3) {
              order: 2;
            }
            &:nth-child(4) {
              order: 6;
            }
            &:nth-child(5) {
              order: 3;
            }
            &:nth-child(6) {
              order: 7;
            }
            &:nth-child(7) {
              order: 4;
            }
            &:has(input[type="button"]) {
              order: 12;
            }
          }
        }
      }
    }
  }

  form {
    .form__field-row {
      position: relative;
      width: 100%;
      select,
      input:not(input[type="button"]) {
        color: $primary;
        height: 3.5rem;
        padding: 0.5rem 1rem;
        margin-bottom: 1.5rem;
        &:focus {
          border-bottom: 1px solid $berry2;
        }
      }
      input[type="button"] {
        display: block;
        margin: 0.5rem 0 0 auto;
      }
      input[type="button"][disabled] {
        background-color: $grey200;
        color: $grey500;
        opacity: 1;
      }
      &.checkbox {
        @include breakpoint(small only) {
          margin-bottom: 2rem;
        }
        input[type="checkbox"] {
          height: 1.5rem;
          width: 1rem;
          accent-color: $grey700;
        }
        label {
          top: 0;
          margin-left: 0.75rem;
          margin-right: 0.5rem;
        }
      }

      &:has(input[name*="phone"]) {
        .country__code-container {
          position: absolute;
          height: 3.5rem;
          display: flex;
          align-items: center;
          justify-items: center;
        }
        input {
          position: relative;
          transition: padding 0.3s ease;
        }
        #cc__selector[disabled] {
          cursor: not-allowed;
          opacity: 0.5;
        }
        .country__code-selector {
          appearance: none;
          box-shadow: none;
          display: inline-flex;
          position: relative;
          left: 1rem;
          bottom: 0.5rem;
          align-items: center;
          align-items: flex-end;
          line-height: 1.4;
          vertical-align: top;
          border: 1px solid transparent;
          border-radius: 4px;
          font-size: 1rem;
          transition: all 400ms cubic-bezier(0.075, 0.82, 0.165, 1) 0s, visibility 0s ease 0s;
          transform-style: preserve-3d;
          cursor: pointer;
          font-weight: 400;
          white-space: nowrap;
          padding: calc(0.375rem - 1px) 0px;
          flex-direction: row;
          -webkit-box-pack: justify;
          justify-content: space-between;
          height: 2rem;
          align-self: flex-end;
          margin-bottom: 1px;
          background-color: transparent;
          color: rgb(71, 90, 112);
          z-index: 10;
          pointer-events: pointer;
          &:focus {
            border: $berry2 1px solid;
          }
          &.active {
            .country__code-dropdown {
              display: block;
              .country__code-row:hover {
                background-color: #e6f1fc;
              }
            }
            svg {
              rotate: (180deg);
            }
          }
          .country__code-selected {
            display: flex;
            flex-flow: row;
            align-items: center;
            font-size: 16px;
            font-weight: 500;

            .country__code-flag {
              margin-right: 0.5rem;
              img {
                all: unset;
              }
            }

            .country__code-value {
              margin-right: 0.5rem;
            }
            svg {
              display: none;
              width: 0;
            }
          }
          .country__code-dropdown {
            display: none;
            position: absolute;
            overflow: hidden auto;
            max-height: 10.25rem;
            padding: 0.5rem;
            background-color: rgb(255, 255, 255);
            border-radius: 4px;
            box-shadow: 0px 4px 8px rgba(147, 147, 147, 0.1);
            left: 0rem;
            top: 2rem;
            min-width: 18rem;
            max-width: 26rem;
            z-index: 10000;

            .country__code-row {
              transition: all 400ms cubic-bezier(0.075, 0.82, 0.165, 1) 0s, visibility 0s ease 0s;
              transform-style: initial;
              cursor: pointer;
              display: flex;
              -webkit-box-pack: justify;
              justify-content: space-between;
              padding: 0.5rem;
              white-space: nowrap;
              width: 100%;
              border-radius: 4px;
              color: rgb(71, 90, 112);
              background-color: rgb(255, 255, 255);
              border: 1px solid transparent;
              text-align: left;
              font-size: 16px;
              font-weight: 500;
              .country__code {
                width: 100%;
                max-width: 16rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                img {
                  all: unset;
                  border-radius: 0px;
                }
              }
            }
          }
        }
      }
      &.invalid:not(:has(input[type="button"])) {
        select[required],
        input[required],
        textarea[required] {
          border: 1px solid $red600;
          margin-bottom: 0;
        }
      }
      &.invalid {
        label {
          color: $red600 !important;
          span {
            display: inline-block !important;
            color: $red600 !important;
          }
        }
        &.email {
          &:after {
            content: "A valid email is required" !important;
          }
        }
        &.phone {
          &:after {
            content: "A valid phone number is required" !important;
          }
        }
        &.text:has(input[name="first_name"]) {
          &:after {
            content: "First name is required";
          }
        }
        &.text:has(input[name="last_name"]) {
          &:after {
            content: "Last name is required";
          }
        }
        &:after {
          color: $red600 !important;
        }
      }
    }
    .form__field-row:has(input[type="hidden"]) {
      position: absolute;
      position: absolute !important;
      width: 1px !important;
      height: 1px !important;
      padding: 0 !important;
      margin: -1px !important;
      overflow: hidden !important;
      clip: rect(0, 0, 0, 0) !important;
      white-space: nowrap !important;
      border: 0 !important;
    }
  }
  &.download_hero {
    position: relative;
    z-index: 2;
    width: 345px;
    padding: 1.5rem;
    min-height: 400px;
    max-width: 90%;
    margin: 0 auto;
    border-radius: 8px;
    box-shadow: $shadowlight20;
    background: $white;

    @include breakpoint(medium only) {
      width: 340px;
    }
    @media (min-width: 700px) and (max-width: 767px) {
      width: 300px;
    }
    @include breakpoint(small only) {
      margin-top: 25px;
      width: 345px;
      max-width: 100%;
    }
  }
  &.download_page,
  &.download_hero {
    form {
      .form__field-row {
        input[type="button"] {
          padding: 1rem 1rem 1rem 2.5rem;
          background-repeat: no-repeat;
          background-size: 20px;
          background-position: left 0.75rem center;
          background-image: url("../images/icons/button-icons/download-enabled.svg");

          [disabled],
          &:disabled {
            background-image: url("../images/icons/button-icons/download-disabled.svg");
          }
          @include breakpoint(small only) {
            background-image: none;
            padding: 1rem;
            &[disabled],
            &:disabled {
              background-image: none;
            }
          }
        }
      }
    }
  }
  &.webinar,
  &.download_page,
  &.horizontal {
    form {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      flex-flow: wrap;
      .form__field-row {
        @include breakpoint(medium up) {
          width: calc(50% - 0.75rem);
          &:last-child {
            width: fit-content;
            margin: 0 0 0 auto;
          }
        }
      }
    }
  }
  &.download_page {
    @include breakpoint(small only) {
      padding: 1.5rem;
      width: 345px;
      max-width: 100%;
      margin: 0 auto;
      box-shadow: $shadowlight20;
    }
  }
  &.v2 {
    border-radius: 1rem;
    .form__field-row {
      input[type="button"] {
        margin: 0.5rem auto 0 0;
      }
    }
  }
}

.form__field-row {
  display: inline-flex;
  flex-direction: column;
  -webkit-box-pack: start;
  justify-content: flex-start;
  box-sizing: border-box;
  clear: both;
  text-align: left;
  &.select {
    label {
      display: none;
    }
  }
  &:not(:has(.invalid)) {
    &:has(input[value]) label {
      color: $primary;
      span {
        display: none;
      }
    }
  }
  &:not(:has(.select)) {
    label {
      transition: transform 0.1s cubic-bezier(0.1, 0.55, 0.83, 0.67) 0s, color 0.3s ease-out 0s;
      font-size: 1rem;
      position: absolute;
      transform-origin: left top;
      transform: translate(16px, 0px) scale(1);
      color: rgb(71, 90, 112);
      pointer-events: none;
      z-index: 3;
      top: 1rem;
      span {
        color: $red600;
      }
    }
    input:not([type="button"]):focus,
    input:not([type="button"])[value] {
      padding: 1.5rem 1rem 0.5rem;
    }
  }

  &:not(.checkbox) {
    &:focus-within label {
      font-weight: 700;
    }
    &:focus-within label,
    &:has(input[value]) label {
      transform: translate(16px, -12px) scale(0.85);
      color: $grey800;
    }
  }
}
