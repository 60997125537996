@import "../settings";

// FOOTER : START
.off-canvas-wrapper {
  .footer {
    margin-top: 0;
  }
}
.footer:not(.legacy-footer) {
  .main-footer {
    color: $grey500;
    & > .grid-container {
      border-top: 1px solid $grey400;
      padding: 75px 1rem 60px;
      border-bottom: 1px solid $grey400;
    }
    .footer__leaf {
      margin-bottom: 2rem;
    }
    nav > .menu-item {
      @include breakpoint(small only) {
        margin-bottom: 3rem;
        &:nth-child(even) {
          padding-right: 1rem;
        }
      }
    }
  }
  .submenu-section-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: $grey800;
  }
  .footer_section:nth-child(4),
  .footer_section:nth-child(5) {
    @include breakpoint(small only) {
      margin-top: 4rem;
    }
  }
  .footer-submenu-section {
    list-style: none;
    margin: 2.5rem 0 0;
    font-size: 16px;
    li {
      list-style: none;
      margin-bottom: 15px;
      a {
        color: $grey800;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .footer__socials {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include breakpoint(small only) {
      margin-top: 2.5rem;
    }
    a {
      svg {
        width: 1rem;
        height: 1rem;
      }

      svg,
      path {
        transition: fill 0.3s ease;
        fill: $grey500;
      }
      &:hover {
        svg,
        path {
          fill: $green600;
        }
      }
    }
  }
  .footer__country-toggle {
    transition: max-height 0.3s ease;
    position: relative;
    width: 128px;
    padding-top: 40px;
    padding-right: 1rem;
    cursor: pointer;
    margin-left: auto;

    &.is-open {
      .footer__country {
        &.is-current {
          &:after {
            transform: rotate(180deg);
          }
        }
      }
    }
    &:not(.is-open) {
      a {
        display: none !important;
      }
    }
    &.is-open {
      max-height: 86px;
    }

    .footer__country {
      padding: 0.5rem 0;
      display: block;
      margin: 3px;
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;

      img {
        margin-left: auto;
        margin-right: 0.75rem;
        transform: translateY(-1px);
      }
      &.is-current {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        &:hover {
          color: $green600;
        }
        &:after {
          content: "";
          display: inline-block;
          width: 12px;
          height: 8px;
          background-image: url("../images/icons/nav-chevron.svg");
          background-size: contain;
          background-position: center center;
          background-repeat: no-repeat;
          position: relative;
          margin: 0 0 0 0.5rem;
        }
      }
      &:not(.is-current) {
        box-shadow: $shadowdark20;
        background-color: $white;
        border-radius: 8px;
        width: 134px;
        display: inline-block;
        padding: 0.5rem 1rem;
        color: $coal2;
        position: absolute;
        left: auto;
        right: 0;
        white-space: nowrap;
      }
    }

    @include breakpoint(medium only) {
      margin-bottom: 3rem;
    }

    @include breakpoint(small only) {
      margin-left: 0;
      margin-bottom: 3rem;
      .footer__country {
        &.is-current {
          right: auto;
          left: 0;
        }
        &:not(.is-current) {
          left: 0;
          right: auto;
        }
      }
    }
  }

  .legal-footer {
    padding: 2rem 1rem;
    #legal-links {
      list-style: none;
      li {
        margin-bottom: 15px;
        a {
          color: $grey500;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .copyright {
      color: $grey500;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
    }

    .fda-notice {
      @include breakpoint(medium) {
        padding-right: 2rem;
      }
      font-size: 14px;
    }
  }
}
