@import "../settings";

.reveal-overlay {
  padding: 0 1rem;
}
.reveal {
  top: 6.25rem !important;
  @include breakpoint(small only) {
    height: fit-content;
    min-height: fit-content;
    &:not(.video__modal) {
      top: 0.5rem !important;
      .popup__content {
        padding: 1rem 1.5rem 2rem !important;
        .element__section--title {
          max-width: 300px;
        }
      }
    }
  }
  .responsive-embed {
    margin-bottom: 0;
  }
  .widescreen.responsive-embed > .responsive-embed {
    all: unset;
  }
  &.has__video {
    border-radius: 1.5rem;
    padding: 0;
    .widescreen.responsive-embed {
      border-radius: 0.5rem;
    }
    .close-button {
      top: 2rem;
      right: 2rem;
    }
    @include breakpoint(small only) {
      // padding: 1rem;
      border-radius: 1rem;
      .close-button {
        top: 1rem;
        right: 1rem;
      }
    }
  }
}
.reveal .close-button {
  color: $forest2;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 1.5rem;
  top: 1.5rem;
  font-size: 28px;
  font-weight: 400;
  border-radius: 40px;
  width: 40px;
  height: 40px;
  box-shadow: 0px 4px 8px rgba(147, 147, 147, 0.1);
  transition: box-shadow 0.3s ease;
  background-color: $white;
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}

.element__bucket {
  a.bucket__title {
    display: block;
  }
  .bucket__title {
    margin-bottom: 1.5rem;

    &.bold {
      font-weight: 700;
    }
  }
}
.button-group {
  margin-top: 2rem;
}
