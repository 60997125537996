@import "../settings";
// header

header:not(.legacy-header) {
  .menu {
    position: initial;
  }

  #top-bar-menu nav {
    @include breakpoint(small only) {
      width: 100%;
    }
  }

  .navigation-container {
    @include breakpoint(small only) {
      margin-bottom: auto;
    }
  }
  #main-nav .is-drilldown-submenu,
  #main-nav .is-dropdown-submenu {
    @extend .grid-container;
    top: 65px;
    // left: 0 !important;
    right: 0;
    margin: 0 auto;
    padding: 0 2rem;
    border-radius: 0 !important;
    list-style: none;

    @include breakpoint(small only) {
      padding: 0;
    }

    &.js-dropdown-active {
      display: flex;
    }

    &[data-depth="0"] {
      @include breakpoint(medium) {
        &:after {
          content: "";
          display: inline-block;
          height: 100%;
          background-color: rgba($white, 0.7);
          backdrop-filter: blur(12px);
          -webkit-backdrop-filter: blur(12px);
          width: 150vw;
          position: absolute;
          top: 0;
          left: -40vw;
          right: 0;
          margin: 0;
          z-index: -3;
        }
      }
    }

    .menu-description {
      font-size: 1rem;
      font-weight: 400;
      display: block;
    }

    & > li:not(.integrations-menu-item) {
      background-color: white;
      padding: 2rem;

      @include breakpoint(small only) {
        padding: 0;
        .submenu-section {
          padding: 1rem 1rem 2rem;
          a {
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
          }
        }
      }

      a {
        transition: color 0.3s ease;
        display: inline-block;
        position: relative;

        span {
          transition: right 0.3s ease, opacity 0.3s ease;
          content: "";
          width: 13.33px;
          height: 11.67px;
          display: inline-block;
          position: absolute;
          right: -0.5rem;
          bottom: 0;
          top: 0;
          margin: auto 0;
          transform: translateY(-2px);
          opacity: 0;
          svg {
            width: 13.33px;
            height: 11.67px;
            fill: $green600;
          }
        }

        &:hover {
          color: $green600;
          span {
            opacity: 1;
            right: -1.75rem;
          }
        }
      }

      @include breakpoint(medium) {
        margin-bottom: 2rem;
        margin-top: 2rem;
        &:nth-child(1) {
          border-radius: 1.5rem 0 0 1.5rem;
        }
        &:nth-child(3) {
          border-radius: 0 1.5rem 1.5rem 0;
        }
      }
      @include breakpoint(small only) {
        &:nth-child(3) {
          border-radius: 0 0 1.5rem 1.5rem;
        }
      }
    }

    .submenu-section-title {
      padding: 0 0 0 56px;
      display: block;
      font-size: 1.375rem;
      font-weight: 700;
      position: relative;

      @include breakpoint(small only) {
        padding: 1rem 1rem 1rem 72px;
        font-size: 18px;
        line-height: 24px;
      }
      .submenu-section-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        position: absolute;
        left: 0;
        svg {
          width: 20px;
          height: 20px;
        }
        @include breakpoint(small only) {
          background-color: $white !important;
          margin-left: 1rem;
          width: 32px;
          height: 32px;
          transform: translateY(-4px);
          svg {
            width: 16px;
            height: 16px;
          }
        }
      }

      &.featured {
        padding: 0;
        @include breakpoint(small only) {
          padding-left: 1rem;
        }
      }
    }

    .submenu-section {
      padding-bottom: 0.5rem;
      .menu-item {
        a {
          margin-top: 2rem;
          font-weight: 700;
          padding: 0 0 0.25rem;
        }
        &:first-child {
          a {
            margin-top: 1rem;
            @include breakpoint(medium) {
              margin-top: 2rem;
            }
          }
        }
      }
    }

    .mega__menu--featured {
      position: relative;

      &:after {
        content: "";
        display: inline-block;
        height: 100%;
        background-color: $grey200;
        width: 50vw;
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        z-index: -1;
      }
    }
  }
}

.resources-nav.submenu {
  @include breakpoint(small only) {
    &:before {
      content: "Resources";
      color: $grey500;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      padding: 1.5rem 1rem;
      display: inline-block;
      width: 100%;
      background-color: $white;
      border-radius: 1.5rem 1.5rem 0 0;
    }
  }
  & > li.menu-item:nth-child(2) {
    @include breakpoint(large) {
      border-radius: 0 1.5rem 1.5rem 0;
    }
    border-radius: 0 0 1.5rem 1.5rem;
  }
}

.solutions-nav {
  @include breakpoint(small only) {
    &:before {
      content: "Solutions";
      color: $grey500;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      padding: 1.5rem 1rem;
      display: inline-block;
      width: 100%;
      background-color: $white;
      border-radius: 1.5rem 1.5rem 0 0;
    }
  }
  .integrations-menu-item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    @include breakpoint(small only) {
      margin-top: 1rem;
    }
    & > a {
      // padding: 0;
      flex-basis: shrink;
      &:not(.menu-description) {
        @include breakpoint(medium) {
          font-size: 18px !important;
          line-height: 32px !important;
        }
      }
    }
    .menu-description {
      padding: 1rem 0;
      flex-basis: shrink;
      font-weight: 600 !important;
      position: relative;
      padding-right: 20px !important;
      .cta {
        position: absolute;
        width: 20px;
        height: 14px;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto 0;
        transform: translateY(1px);
        display: flex;
        align-items: flex-end;
        justify-content: end;

        svg,
        svg path {
          transition: fill 0.3s ease;
        }
        span {
          transition: opacity 0.3s ease, transform 0.3s ease;
          position: absolute;

          &.chevron {
            width: 8px;
            height: 14px;
            margin-right: 2px;
            fill: $grey800;
          }
          &.arrow {
            opacity: 0;
            width: 16px;
            height: 14px;
            transform: translateX(-3px);
            fill: $grey500;
          }
        }
      }
      &:hover {
        .cta {
          span {
            &.arrow {
              opacity: 1;
              transform: translateX(3px);
              svg,
              svg path {
                fill: $grey500;
              }
            }
            &.chevron {
              opacity: 0;
              transform: translateX(3px);
            }
          }
        }
      }
    }
    .submenu-section {
      flex-basis: 100%;
      width: 100%;
      a {
        transition: filter 0.3s ease;
        filter: grayscale(100%);
        &:hover {
          filter: grayscale(0%);
        }
      }
    }
  }
}

.learn-nav {
  @include breakpoint(medium) {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .menu-item:nth-child(1) {
      grid-column: 1;
      grid-row: 1;
    }
    .menu-item:nth-child(2) {
      grid-column: 1;
      grid-row: 2;
    }
    .menu-item:nth-child(3) {
      grid-column: 1;
      grid-row: 3;
    }
    .menu-item:nth-child(4) {
      grid-column: 1;
      grid-row: 4;
    }

    .menu-item:nth-child(5) {
      grid-column: 2;
      grid-row: 1;
    }
    .menu-item:nth-child(6) {
      grid-column: 2;
      grid-row: 2;
    }
    .menu-item:nth-child(7) {
      grid-column: 2;
      grid-row: 3;
    }
    .menu-item:nth-child(8) {
      grid-column: 2;
      grid-row: 4;
    }
  }
}

.featured-nav {
  .featured-menu-item {
    position: relative;

    .featured-menu-item-thumbnail {
      width: 100%;
      height: 197px;
      border-radius: 1.25rem;
      overflow: hidden;
      margin-top: 13px;
      img {
        width: 100%;
        transition: transform 0.3s;
        transform-origin: 50% 50%;
        backface-visibility: hidden;
        height: 100%;
        object-fit: cover;
        object-position: 0 50%;
      }
    }
    a {
      padding: 1rem 0;
      font-size: 20px !important;
      font-weight: 700;
      position: initial !important;
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
      }
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.header:not(.legacy-header) {
  // position: relative;
  width: 100%;
  z-index: 10 !important;
  min-height: 81px;

  @include breakpoint(medium down) {
    & > .grid-container {
      padding: 0;
    }
  }
  @include breakpoint(medium) {
    // position: absolute;
    top: 0;
    left: 0;
    right: 0;
    .top-bar {
      background-color: transparent;
      ul {
        background-color: transparent;
        li[data-depth="0"] {
          a {
            background-color: transparent;
          }
        }
      }
    }
  }
}

body {
  .content main.main {
    padding-top: 0;
  }
  &.dropdown-opened {
    header {
      background-color: $white;
    }
  }
  header {
    &.is-stuck {
      transition: transform 0.3s ease, background-color 0.3s ease;
      transition-delay: 0.3s;
      background-color: $white;
      @include breakpoint(medium) {
        &.tucked {
          transform: translateY(-81px);
        }
      }
    }
  }
  &:has(.nav__experiment) {
    &.drilldown-opened {
      .title-bar {
        .custom-logo-link {
          @include breakpoint(small only) {
            transform: translateX(-195px) !important;
          }
        }
      }
    }
  }
  &.dropdown-opened {
    @include breakpoint(medium) {
      header.tucked {
        transform: translateY(0px) !important;
      }
    }
    .top-bar {
      &:after {
        background-color: $white;
      }
    }
  }
  &.drilldown-opened {
    .title-bar {
      .custom-logo-link {
        transform: translateX(-100px);
      }
      .drilldown-button {
        transform: translateX(0px);
      }
    }
  }
  &:not(.drilldown-opened) {
    .title-bar {
      .custom-logo-link {
        transform: translateX(0);
      }
      .drilldown-button {
        transform: translateX(-100px);
      }
    }
  }
  &:not(.mobile-nav-open) {
    .title-bar {
      .custom-logo-link {
        transform: translateX(0) !important;
      }
      .drilldown-button {
        transform: translateX(-100px) !important;
      }
    }
  }
  .sticky-container {
    position: absolute;
    width: 100%;
  }
  &:not(:has(.no-nav)) {
    @include breakpoint(medium) {
      .has-announcement {
        padding-top: 81px;
      }
    }
  }
  &:has(.has-announcement) {
    @include breakpoint(medium) {
      .header {
        background-color: $white;
      }
    }
  }
}
.custom-logo-link {
  transition: transform 0.3s ease;
  display: inherit;
}

.custom-logo {
  width: 156px;
}

header:not(.legacy-header) {
  .title-bar {
    padding: 0.875rem 1.33rem;
    @include breakpoint(medium) {
      display: none;
    }
    .title-bar-left {
      width: 40px;
      height: 40px;
      position: relative;
      display: flex;
      a {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto 0;
      }
      .drilldown-button {
        transition: transform 0.3s ease;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto 0;
      }
    }
    .mobile__sign-in {
      margin-left: auto;
      @include breakpoint(small only) {
        margin-right: 1.5rem;
      }
    }

    .menu-icon {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      &.is-open {
        span {
          &:nth-child(1) {
            opacity: 0;
          }
          &:nth-child(2) {
            -webkit-transform: rotate(45deg) translatez(0);
            transform: rotate(45deg) translatez(0);
            -webkit-transform-origin: 50% 51%;
            transform-origin: 50% 51%;
          }
          &:nth-child(3) {
            -webkit-transform: rotate(-45deg) translatez(0);
            transform: rotate(-45deg) translatez(0);
            -webkit-transform-origin: 50% 51%;
            transform-origin: 50% 51%;
          }
          &:nth-child(4) {
            opacity: 0;
          }
        }
      }

      span {
        transition: all 0.3s ease;
        background-color: $grey800;
        display: inline-block;
        width: 20px;
        height: 3px;
        border-radius: 3px;
        position: absolute;
        opacity: 1;
        &:nth-child(1) {
          transform: translateY(-7px);
        }
        &:nth-child(4) {
          transform: translateY(7px);
        }
      }
      &:after {
        display: none;
      }
    }
  }

  .top-bar .top-bar-left {
    display: flex;
  }

  .top-bar .dropdown.menu > li.opens-left > .is-drilldown-submenu {
    left: 0;
    right: auto;
  }

  .top-bar .dropdown.menu .submenu::after {
    right: auto;
    left: 20%;
  }

  #top-bar-menu {
    @include breakpoint(small only) {
      max-height: calc(100vh - 75px);
      height: calc(100vh - 75px);
      overflow-y: auto;
      background-color: rgba($white, 0.7);
      backdrop-filter: blur(12px);
      -webkit-backdrop-filter: blur(12px);
    }
  }

  .top-bar-buttons {
    @include breakpoint(large) {
      .button:nth-child(2) {
        margin-left: 1.5rem;
      }
    }
    @include breakpoint(medium) {
      white-space: nowrap;
      .button:nth-child(2) {
        margin-left: 1rem;
      }
    }
    @include breakpoint(small only) {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      padding-top: 1.5rem;
    }
    .button.v2 {
      padding: 12px 22px;
    }
  }

  @include breakpoint(medium down) {
    .is-drilldown {
      min-height: 200px;
    }
  }

  #main-nav {
    @include breakpoint(medium only) {
      position: unset;
    }

    @include breakpoint(small only) {
      border-radius: 1rem;
      background-color: $white;
      padding: 1rem;
    }

    & > .menu-item {
      &[data-depth="0"] {
        a {
          transition: color 0.3s ease;

          &:hover {
            color: $grey500;
          }
        }
      }
      @include breakpoint(small only) {
        &[data-depth="0"] {
          a {
            font-size: 20px;
            font-weight: 700;
            padding: 0.75rem 0;
          }
        }
      }
      & > .submenu {
        @include breakpoint(medium only) {
          top: 64px;
        }
        @supports (-webkit-touch-callout: none) {
          top: 84px;
        }
        @include breakpoint(large) {
          margin-top: 1rem;
        }
        @include breakpoint(medium only) {
          margin-top: rem-calc(15);
        }
        @include breakpoint(small only) {
          // position: relative;
          min-width: 300px;
          margin-bottom: 1rem;
          top: 0;
        }
      }
      &.is-drilldown-submenu-parent {
        transition: background-color 0.3s ease;
        border-radius: 24px;

        & > a {
          &:after {
            border: none;
            background-image: url("../images/icons/nav-chevron.svg");
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            height: 28px;
            width: 16px;
            transform: rotate(-90deg) translateX(8px);
          }
        }

        &:not(.is-active) {
          background-color: transparent;
        }

        &.is-active {
          @include breakpoint(medium) {
            & > a {
              &:after {
                transform: rotate(180deg);
              }
            }
          }
        }

        a {
          background-color: transparent;
        }
      }

      @include breakpoint(small only) {
        &.is-accordion-submenu-parent {
          &[aria-expanded="true"] {
            & > a {
              &:before {
                transform: rotate(180deg);
              }
            }
          }
          & > a {
            &:after {
              display: none !important;
            }
            &:before {
              content: "";
              display: inline-block;
              width: 24px;
              height: 16px;
              background-image: url("../images/icons/nav-chevron.svg");
              background-size: contain;
              background-position: center center;
              background-repeat: no-repeat;
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
              margin: auto 0;
            }
          }
        }
      }

      & > a {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: $coal3;
        margin-right: 1rem;
        @media (max-width: 725px) and (min-width: 700px) {
          font-size: 16px;
          line-height: 25px;
        }
        @include breakpoint(small only) {
          margin-right: 0;
          padding: 0.5rem 0;
        }
      }
    }

    .is-dropdown-submenu-parent {
      position: unset;
      &.current-menu-parent {
        & > a {
          color: $forest2;
        }
      }
      &.is-active {
        & > a {
          &:after {
            transform: rotate(180deg);
          }
        }
      }
      & > a {
        position: relative;
        &:after {
          content: "";
          display: inline-block;
          width: 12px;
          height: 8px;
          background-image: url("../images/icons/nav-chevron.svg");
          background-size: contain;
          background-position: center center;
          background-repeat: no-repeat;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto 0;
          @include breakpoint(small only) {
            width: 24px;
            height: 12px;
          }
        }
      }
    }
    .orange {
      .submenu-section-icon {
        background-color: #fcf3dd;
        svg {
          fill: $yellow900;
        }
      }
      @include breakpoint(small only) {
        .submenu-section-title {
          background-color: #fcf3dd;
        }
      }
    }
    .blue {
      .submenu-section-icon {
        background-color: $blue200;
        svg {
          fill: $blue600;
        }
      }
      @include breakpoint(small only) {
        .submenu-section-title {
          background-color: $blue200;
        }
      }
    }
    .purple {
      .submenu-section-icon {
        background-color: $purple200;
        svg {
          fill: $purple600;
        }
      }
      @include breakpoint(small only) {
        .submenu-section-title {
          background-color: $purple200;
        }
      }
    }
    .drilldown.menu > li > a,
    .top-bar .top-bar-left > ul {
      background-color: transparent;
    }

    .menu-item {
      color: $coal2;
      &.current_page_item,
      &.active {
        &.has__icon {
          color: $forest2;
          .nav__icon {
            background-color: $forest4;
            svg path {
              fill: $forest2;
            }
          }
        }
        & > a {
          color: $forest2;
        }
      }
      a {
        color: $coal2;
      }
      ul {
        a {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        }
      }

      &.is-new a {
        &:after {
          position: relative;
          content: "New";
          display: inline-block;
          background-color: $green300;
          font-size: 16px;
          font-weight: 700;
          line-height: 20px;
          padding: 1px 12px;
          border-radius: 0.75rem;
          margin-left: 0.5rem;
        }
      }
    }
  }

  .js-drilldown-back {
    position: absolute;
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
  }
}
.consent__popup {
  transition: transform 0.3s ease;
  @extend .grid-container;
  position: fixed;
  margin: auto auto 0;
  top: auto;
  bottom: 0;
  left: 1rem;
  right: 1rem;
  padding: 1.5rem;
  background-color: $white;
  z-index: 10;
  width: calc(100% - 2rem);
  border-radius: 0.75rem;
  box-shadow: $shadowdark40;
  transform: translateY(100%);

  .consent__popup-content {
    p {
      margin-bottom: 0;
    }
  }

  .consent__popup-buttons {
    button:first-child {
      @include breakpoint(small only) {
        margin-top: 1rem;
      }
    }

    button:last-child {
      @include breakpoint(medium) {
        margin-left: 2rem;
      }
      @include breakpoint(small only) {
        margin-top: 2rem;
      }
    }
  }
}
.polaris-navigation {
  .is-anchored {
    background-color: $grey200;
  }
}

//GPC prompt styling
.gpc-prompt {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: #275e43;
  color: white;
  width: 300px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 12px;
  opacity: 0.9;
  transition: all 0.3s ease;
  @include breakpoint(small only) {
    width: 90%;
    max-width: 200px;
    padding: 10px;
  }

  .gpc-prompt-header {
    display: flex;
    align-items: flex-start;
    position: relative;

    .gpc-prompt-close {
      background: none;
      border: none;
      color: white;
      font-size: 18px;
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 10px;
      padding: 0;
      line-height: 1;

      &:hover {
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }

  .gpc-prompt-content {
    margin-right: 30px; // Space between the text and the "X"

    p {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      text-align: left;

      @include breakpoint(small only) {
        font-size: 12px;
      }
    }
  }
}

// vvvvv Animations

// .submenu {
//   opacity: 0;
//   transition: opacity 0.2s ease;
//   transition-delay: 0.075s;
//   display: none; // This is set by default in Foundation

//   body.dropdown-opened & {
//     opacity: 1;
//   }
// }

// .submenu {
//   @include breakpoint(medium) {
//     .submenu-section-title {
//       transition: opacity 0.3s ease, transform 0.3s ease;
//     }
//     .submenu-section {
//       .menu-item {
//         $initial-delay: 0.075s;
//         $increment: 0.075s;
//         $reset-after: 4;

//         transition: opacity 0.3s ease, transform 0.3s ease;

//         @for $i from 1 through 12 {
//           $adjusted-i: ($i - 1) % $reset-after + 1;

//           &:nth-child(#{$i}) {
//             transition-delay: $initial-delay + $increment * ($adjusted-i - 1);
//           }
//         }
//       }
//       &.integrations-nav a {
//         $initial-delay: 0.075s;
//         $increment: 0.075s;
//         $reset-after: 6;

//         transition: opacity 0.3s ease, transform 0.3s ease;

//         @for $i from 1 through 12 {
//           $adjusted-i: ($i - 1) % $reset-after + 1;

//           &:nth-child(#{$i}) {
//             transition-delay: $initial-delay + $increment * ($adjusted-i - 1);
//           }
//         }
//       }
//     }

//     &:not(.animate) {
//       .submenu-section-title {
//         opacity: 0;
//         transform: translateX(-10px);
//       }
//       .submenu-section {
//         .menu-item {
//           opacity: 0;
//           transform: translateY(10px);
//         }
//         &.integrations-nav a {
//           opacity: 0;
//           transform: translateY(10px);
//         }
//       }
//     }

//     &.animate {
//       .submenu-section-title {
//         opacity: 1;
//         transform: translateX(0);
//       }
//       .submenu-section {
//         .menu-item {
//           opacity: 1;
//           transform: translateY(0);
//         }
//         &.integrations-nav a {
//           opacity: 1;
//           transform: translateY(0);
//         }
//       }
//     }
//   }
// }
