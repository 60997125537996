@import "../settings";

@keyframes scroller {
  0% {
    top: 6px;
    opacity: 1;
  }
  25% {
    top: 10px;
    opacity: 1;
  }
  50% {
    top: 10px;
    opacity: 0;
  }
  75% {
    top: 6px;
    opacity: 0;
  }
  100% {
    top: 6px;
    opacity: 1;
  }
}

.scroll__instructions {
  transition: opacity 0.3s ease;
  font-size: 18px;
  opacity: 1;
  pointer-events: none;
  &.center {
    margin: 1rem auto 3rem;
    width: 100%;
  }
  &.absolute {
    position: absolute;
    bottom: 3rem;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  &.relative {
    position: relative;
    display: inline-block;
    padding-left: 8px;
  }
  &.fade {
    opacity: 0;
  }
  .scroll__animation {
    width: 18px;
    height: 24px;
    display: inline-block;
    border-radius: 60px;
    border: 2px solid $primary;
    position: relative;
    transform: translateX(-8px) translateY(5px);

    &:before {
      content: "";
      height: 6px;
      width: 2px;
      border-radius: 2px;
      background-color: $primary;
      position: absolute;
      top: 6px;
      left: 0px;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      animation: scroller 2.5s infinite forwards;
    }
  }
}
