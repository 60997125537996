@import "settings";

$colors: (
  "primary": $primary,
  "black": $black,
  "white": $white,
  "greenery": $greenery,
  "coal-1": $coal1,
  "coal-2": $coal2,
  "coal-3": $coal3,
  "coal-4": $coal4,
  "coal-5": $coal5,
  "coal-6": $coal6,

  "forest-1": $forest1,
  "forest-2": $forest2,
  "forest-3": $forest3,
  "forest-4": $forest4,
  "forest-5": $forest5,

  "berry-1": $berry1,
  "berry-2": $berry2,
  "berry-3": $berry3,
  "berry-4": $berry4,
  "berry-5": $berry5,

  "zest-1": $zest1,
  "zest-2": $zest2,
  "zest-3": $zest3,
  "zest-4": $zest4,
  "zest-5": $zest5,

  "plum-1": $plum1,
  "plum-2": $plum2,
  "plum-3": $plum3,
  "plum-4": $plum4,
  "plum-5": $plum5,

  "cherry-1": $cherry1,
  "cherry-2": $cherry2,
  "cherry-3": $cherry3,
  "cherry-4": $cherry4,
  "cherry-5": $cherry5,

  //new palette
  "grey-100": $grey100,
  "grey-200": $grey200,
  "grey-300": $grey300,
  "grey-400": $grey400,
  "grey-500": $grey500,
  "grey-600": $grey600,
  "grey-700": $grey700,
  "grey-800": $grey800,
  "grey-900": $grey900,

  "green-100": $green100,
  "green-200": $green200,
  "green-300": $green300,
  "green-400": $green400,
  "green-500": $green500,
  "green-600": $green600,
  "green-700": $green700,
  "green-800": $green800,
  "green-900": $green900,

  "blue-100": $blue100,
  "blue-200": $blue200,
  "blue-300": $blue300,
  "blue-400": $blue400,
  "blue-500": $blue500,
  "blue-600": $blue600,
  "blue-700": $blue700,
  "blue-800": $blue800,
  "blue-900": $blue900,

  "red-100": $red100,
  "red-200": $red200,
  "red-300": $red300,
  "red-400": $red400,
  "red-500": $red500,
  "red-600": $red600,
  "red-700": $red700,
  "red-800": $red800,
  "red-900": $red900,

  "purple-100": $purple100,
  "purple-200": $purple200,
  "purple-300": $purple300,
  "purple-400": $purple400,
  "purple-500": $purple500,
  "purple-600": $purple600,
  "purple-700": $purple700,
  "purple-800": $purple800,
  "purple-900": $purple900,

  "orange-100": $orange100,
  "orange-200": $orange200,
  "orange-300": $orange300,
  "orange-400": $orange400,
  "orange-500": $orange500,
  "orange-600": $orange600,
  "orange-700": $orange700,
  "orange-800": $orange800,
  "orange-900": $orange900,

  "yellow-100": $yellow100,
  "yellow-200": $yellow200,
  "yellow-300": $yellow300,
  "yellow-400": $yellow400,
  "yellow-500": $yellow500,
  "yellow-600": $yellow600,
  "yellow-700": $yellow700,
  "yellow-800": $yellow800,
  "yellow-900": $yellow900,

  "sand-100": $sand100,
  "sand-200": $sand200,
  "sand-300": $sand300,
  "sand-400": $sand400,
  "sand-500": $sand500,
  "sand-600": $sand600,
  "sand-700": $sand700,
  "sand-800": $sand800,
  "sand-900": $sand900,
);
//font colouring
.has-text-color {
  h1,
  h2,
  h3,
  h4,
  h4,
  h5,
  h6 {
    color: inherit;
  }

  @each $color-name, $color-value in $colors {
    &.has-#{$color-name}-color {
      color: $color-value;
      fill: $color-value;
      path,
      svg {
        fill: $color-value;
      }
    }
  }
}

.has-mobile-text-color {
  @include breakpoint(small only) {
    h1,
    h2,
    h3,
    h4,
    h4,
    h5,
    h6 {
      color: inherit;
    }

    @each $color-name, $color-value in $colors {
      &.has-#{$color-name}-color {
        color: $color-value;
        fill: $color-value;
        path,
        svg {
          fill: $color-value;
        }
      }
    }
  }
}

.has-background {
  @each $color-name, $color-value in $colors {
    &.has-#{$color-name}-background-color {
      background-color: $color-value;
    }
  }
  &.has-light-gradient-background-color {
    background: linear-gradient(180deg, $green200 0%, rgba(255, 255, 255, 0) 100%);
  }
}

.has-mobile-background {
  @include breakpoint(small only) {
    @each $color-name, $color-value in $colors {
      &.has-#{$color-name}-background-color {
        background-color: $color-value !important;
      }
    }
  }
}

.has-gradient {
  @each $color-name, $color-value in $colors {
    @include gradient($color-name, $color-value);
  }
}

.light-gradient {
  background: linear-gradient(180deg, $green200 0%, rgba(255, 255, 255, 0) 100%);
}

.light-orange-gradient {
  background: linear-gradient(180deg, $orange200 0%, rgba(255, 255, 255, 0) 100%);
}

.light-blue-gradient {
  background: linear-gradient(180deg, $blue200 0%, rgba(255, 255, 255, 0) 100%);
}

.dark-gradient {
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
}

.wp-block-group.has-background:not(.extended-container):not(.round-container) {
  padding: 1.25em 2.375em;
  margin: 0 0 1.5rem;
}
.wp-block-group.extended-container:has(.tab__controls-block) {
  @include breakpoint(small only) {
    padding-top: 0;

    section {
      padding-left: 0;
      padding-right: 0;
    }
    .tabbed__scroll-cards {
      margin-left: 0;
      margin-right: 0;
    }
  }
  @include breakpoint(medium down) {
    .tabbed__scroll-cards {
      // overflow: visible;
    }
  }
}

pre.wp-block-code,
figure.wp-block-pullquote.is-style-solid-color {
  margin: 1.5em 0;
}

@include breakpoint(medium) {
  .callout .button-group a {
    margin-bottom: 0px;
  }
  .callout .button-group {
    align-items: center;
  }
}

.shadowlight20 {
  box-shadow: $shadowlight20;
}
.shadowlight40 {
  box-shadow: $shadowlight40;
}
.shadowlight60 {
  box-shadow: $shadowlight60;
}
.shadowlight80 {
  box-shadow: $shadowlight80;
}
.shadowlight100 {
  box-shadow: $shadowlight100;
}
.shadowdark20 {
  box-shadow: $shadowdark20;
}
.shadowdark40 {
  box-shadow: $shadowdark40;
}
.shadowdark60 {
  box-shadow: $shadowdark60;
}
.shadowdark80 {
  box-shadow: $shadowdark80;
}
.shadowdark100 {
  box-shadow: $shadowdark100;
}
