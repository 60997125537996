@import "../settings";

.component__secondary-nav {
  &:not(.v2) {
    border-bottom: 1px solid $coal4;
  }
  &.breadcrumbs {
    padding: rem-calc(20) 0;
    @include breakpoint(medium down) {
      padding: 1rem;
    }
    margin-bottom: 0;
    .breadcrumbs {
      margin-bottom: 0;
      li {
        line-height: 24px;
        span,
        a {
          font-size: 1rem;
          line-height: 24px;
        }
        a {
          font-weight: 600;
          color: $grey600;
        }
        span {
          font-weight: 800;
          color: $grey800;
        }
      }
      svg {
        margin: 0 1rem;
        fill: $grey800;
        width: 7.5px;
        path {
          fill: $grey800;
        }
      }
      li:not(:last-child):after {
        display: none;
      }
    }
  }

  &.quick {
    padding: 0.5rem 0;
    @include breakpoint(medium down) {
      padding: 1rem;
      .grid-container {
        padding: 0;
      }
    }
    .secondary__nav-tag {
      color: $grey800;
      font-weight: 800;
      font-size: 20px;
      line-height: 18px;
      margin-right: rem-calc(20);
      padding: rem-calc(15) 0;
      &.count--4 {
        @media (max-width: 1100px) and (min-width: 1025px) {
          display: block;
          width: 100%;
          padding-left: rem-calc(12);
        }
      }
    }

    a {
      transition: color 0.3s ease;
      color: $primary;
      font-weight: 600;
      font-size: 18px;
      line-height: 16px;
      padding: rem-calc(15) 0;
      @include breakpoint(large) {
        margin: 0 rem-calc(20);
      }
      @include breakpoint(medium down) {
        margin: 0.5rem 0;
      }

      &:hover {
        color: $forest1;
        svg {
          color: $forest1;
        }
      }
    }
  }
  svg {
    transition: color 0.3s ease;
    margin-left: 0.5rem;
    color: $forest2;
    pointer-events: none;
  }
}

.component__secondary-nav.navigation.v2 {
  padding: 0 1rem;
  text-align: center;
  .navigation-container {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin: 1rem auto;
    max-width: 100%;
    background-color: $grey200;
    border-radius: 40px;

    @include breakpoint(medium) {
      min-width: 800px;
    }

    a {
      padding: 20px 40px;
      border-radius: 40px;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      color: $grey700;

      &.is-current {
        background-color: $grey700;
        color: $white;
      }
    }
  }
  @include breakpoint(small only) {
    .navigation-container {
      flex-direction: column;
      a {
        width: 100%;
        text-align: center;
      }
    }
  }
}

.pill__text-items {
  position: relative;
  padding: 1rem 5rem;
  @include breakpoint(medium down) {
    padding: 5rem 1rem;
    justify-content: center;
  }
  div {
    position: relative;
    z-index: 2;
    font-weight: 600;
    margin: 0.5rem 0;
    padding: 0 0.5rem;
    font-size: 18px;
    line-height: 28px;
  }
  &:after {
    content: "";
    width: 100%;
    height: 220px;
    min-height: 220px;
    border-radius: 110px;
    display: inline-block;
    background-color: $forest5;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1;
    @include breakpoint(medium down) {
      width: 220px;
      height: 100%;
      transform: rotate(45deg);
    }
  }
}

body.single-post {
  .component__secondary-nav {
    &.breadcrumbs {
      margin-bottom: 2rem;
    }
  }
}

section.component__secondary-nav.v2[data-padding],
section.component__secondary-nav.v2[data-margin],
section.component__secondary-nav.v2[data-mobile-padding],
section.component__secondary-nav.v2[data-mobile-margin] {
  padding-left: 0rem;
  padding-right: 0rem;
}
