@import "../settings";

.button-group .button {
  @include breakpoint(small only) {
    margin-right: 0;
  }
  margin-bottom: 0rem;

  &:not(:first-child) {
    @include breakpoint(small only) {
      margin-top: 2rem;
    }
  }
  &:only-child {
    @include breakpoint(small only) {
      margin-top: 0;
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.button {
  transition: color 0.3s ease, border-color 0.3s ease, background-color 0.3s ease;
  line-height: 16px;
  font-weight: 600;

  &[isLoading="true"] {
    position: relative;
    color: transparent !important;
    .button__icon {
      opacity: 0;
    }

    &.light-loading-image {
      &:before {
        background-image: url("../images/icons/button-icons/loading.svg");
      }
    }
    &.dark-loading-image {
      &:before {
        background-image: url("../images/icons/button-icons/loading-dark.svg");
      }
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      display: block;
      width: 22px;
      height: 22px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      animation: rotate 1s linear infinite reverse;
    }
  }

  &:hover {
    &.animate-right {
      svg {
        transform: translateX(0.25rem);
      }
    }
    &.animate-left {
      svg {
        transform: translateX(-0.25rem);
      }
    }
  }
  &.icon-left {
    svg {
      margin-right: 0.5rem;
    }
  }
  &.icon-right {
    svg {
      margin-left: 0.5rem;
    }
  }
  &.chevron-up,
  &.chevron-down {
    svg {
      transform: translateY(-2px);
    }
  }
  &.primary {
    svg,
    path {
      fill: $white;
    }
    &.hollow {
      svg,
      path {
        fill: $grey700;
      }
      &:hover {
        svg,
        path {
          fill: $grey800;
        }
      }
    }
  }

  &.white {
    &.on-forest {
      color: $grey700;
      svg {
        fill: $grey700;
      }
    }
    &.on-coal {
      color: $coal2;
      svg {
        fill: $coal2;
      }
    }
  }
  &.transparent {
    transition: color 0.3s ease;
    background-color: transparent;
    border: transparent;
    color: $grey700;
    padding: 1rem 0;
    box-shadow: none !important;
    font-size: 18px;
    line-height: 16px;
    font-weight: 600;
    &.blue {
      color: $coal2;
    }
    &:disabled {
      &:hover {
        background-color: transparent;
      }
    }

    svg {
      fill: $grey700;
    }

    &:hover {
      color: $grey800;
      &.blue {
        color: $coal1;
      }
      svg {
        fill: $grey800;
      }
    }

    &.white {
      color: $white;
      background-color: transparent !important;
      &:hover {
        text-decoration: underline;
      }
      svg {
        fill: $white;
      }
      &.has__icon {
        text-decoration: none;
      }
    }
  }

  &.fact-button {
    svg {
      &.fa-check {
        margin-left: 0;
        margin-right: 0.5rem;
      }
      .fa-primary {
        fill: $grey700;
      }
      .fa-secondary {
        fill: $forest4;
      }
    }
  }

  &.hollow {
    background-color: $white;
    &.no__text {
      border: none;
      &:hover {
        border: none;
      }
    }
    &:not(:disabled) {
      &:hover {
        background-color: $white;
        svg,
        svg path {
          fill: $grey800;
        }
      }
    }

    &.alternate {
      border-color: #c5d7e5;
    }

    svg,
    svg path {
      fill: $grey700;
    }
  }
  box-shadow: $shadowbutton;
  &:not(:disabled) {
    &:hover {
      box-shadow: $shadowbuttonhover;
    }
  }

  &:not(.no__text) {
    &.icon__position-right {
      svg {
        margin-left: 1rem;
      }
    }
    &.icon__position-left {
      svg {
        margin-right: 1rem;
      }
    }
  }

  &.no__text {
    border-radius: 20px;
    text-align: center;
    padding: 0.5rem;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin: 0;
    }
  }

  svg {
    transition: transform 0.3s ease, fill 0.3s ease;
    path {
      transition: fill 0.3s ease;
    }
  }
  &.back__to-top {
    color: $primary;
    background-color: transparent;
    box-shadow: none !important;
    svg,
    path {
      fill: $grey700;
    }
    &:hover {
      color: $grey800;
      background-color: transparent;
      svg,
      path {
        fill: $grey800;
      }
    }
  }
  &.purple {
    color: $white;
    background-color: $purple700;
    &:hover {
      background-color: $purple800;
    }
    svg,
    path {
      fill: $white;
    }
    &.hollow {
      svg,
      path {
        fill: $purple700;
      }
      &:hover {
        svg,
        path {
          fill: $purple800;
        }
      }
    }
  }
}

.tag {
  transition: all 0.3s ease;
  background-color: $white;
  color: $coal2;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  margin: 0.33rem;
  border: 1px solid $coal5;
  display: inline-block;
  width: auto;
  &.alert {
    border: 1px solid $cherry2;
    background-color: $cherry2;
    color: $white;
    &:hover {
      border: 1px solid $cherry2;
    }
    &.is-active,
    &.is-current {
      font-weight: 800;
    }
  }
  &.warning {
    border: 1px solid $zest2;
    background-color: $zest2;
    color: $white;
    &:hover {
      border: 1px solid $zest2;
    }
    &.is-active,
    &.is-current {
      font-weight: 800;
    }
  }
  &.success {
    border: 1px solid $forest3;
    background-color: $forest3;
    color: $white;
    &:hover {
      border: 1px solid $forest3;
    }
    &.is-active,
    &.is-current {
      font-weight: 800;
    }
  }
  @include breakpoint(medium down) {
    font-size: 14px;
    padding: 0.25rem 1rem;
  }
  &:hover {
    border: 1px solid $coal2;
  }
  &.is-current,
  &.is-active {
    background-color: $coal2;
    color: $white;
    font-weight: 800;
    border: 1px solid $coal2;
  }
}

#back-to-top {
  a {
    color: $primary;
  }
}

$colors: (
  grey: (
    700: $grey700,
    800: $grey800,
  ),
  blue: (
    700: $blue700,
    800: $blue800,
  ),
  green: (
    700: $green700,
    800: $green800,
  ),
  red: (
    700: $red700,
    800: $red800,
  ),
  purple: (
    700: $purple700,
    800: $purple800,
  ),
  orange: (
    700: $orange700,
    800: $orange800,
  ),
  yellow: (
    700: $yellow700,
    800: $yellow800,
  ),
  white: (
    700: $white,
    800: $white,
  ),
);

@mixin colorStyle($color-name) {
  $color-map: map-get($colors, $color-name);
  color: $white;
  border-color: map-get($color-map, 700);
  background-color: map-get($color-map, 700);

  svg,
  svg,
  path {
    fill: $white;
  }

  &:hover {
    border-color: map-get($color-map, 800);
    background-color: map-get($color-map, 800);
  }

  &.transparent {
    color: map-get($color-map, 700);

    svg,
    svg,
    path {
      fill: map-get($color-map, 700);
    }

    &.video-icon {
      .button__icon {
        background-color: map-get($color-map, 700);
        svg,
        svg path {
          fill: $white;
        }
      }
    }

    &:hover {
      color: map-get($color-map, 800);
      svg,
      svg path {
        fill: map-get($color-map, 800);
      }
      &.video-icon {
        .button__icon {
          background-color: map-get($color-map, 800);
          svg,
          svg path {
            fill: $white;
          }
        }
      }
    }
  }

  &.hollow {
    color: map-get($color-map, 700);
    border-color: map-get($color-map, 700);
    background-color: $white;

    svg,
    svg path {
      fill: map-get($color-map, 700);
    }

    &.video-icon {
      .button__icon {
        background-color: map-get($color-map, 700);
        svg,
        svg path {
          fill: $white;
        }
      }
    }

    &:hover {
      color: map-get($color-map, 800);
      border-color: map-get($color-map, 800);
      background-color: $white;

      svg,
      svg path {
        fill: map-get($color-map, 800);
      }

      &.video-icon {
        .button__icon {
          background-color: map-get($color-map, 800);
        }
      }
    }
  }
  &.clear.white {
    color: $white;
    border-color: $white;
  }

  &.clear {
    color: map-get($color-map, 700);
    border-color: map-get($color-map, 700);
    background-color: transparent;
    svg,
    svg path {
      fill: map-get($color-map, 700);
    }

    &.video-icon {
      .button__icon {
        background-color: map-get($color-map, 700);
        svg,
        svg path {
          fill: $white;
        }
      }
    }

    &:hover {
      color: map-get($color-map, 800);
      border-color: map-get($color-map, 800);

      svg,
      svg path {
        fill: map-get($color-map, 800);
      }

      &.video-icon {
        .button__icon {
          background-color: map-get($color-map, 800);
        }
      }
    }
  }
}
// .button-group .button.v2:not(:first-child) {
//   margin-top: 1.5rem;
// }
.button {
  padding: 1.25rem 2rem;
  border-radius: 8px;
  &.v2 {
    transition: all 0.3s ease;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    &.grey800 {
      color: $white;
      border-color: $grey800;
      background-color: $grey800;
      &.hollow {
        color: $grey800;
        border-color: $grey800;
        background-color: $white;
      }
      &.clear {
        color: $grey800;
        border-color: $grey800;
        background-color: transparent;
      }
      &.transparent {
        border: none;
        color: $grey800;
        background-color: transparent;
      }
    }
    &.grey {
      @include colorStyle(grey);
    }
    &.blue {
      @include colorStyle(blue);
    }
    &.green {
      @include colorStyle(green);
    }
    &.red {
      @include colorStyle(red);
    }
    &.purple {
      @include colorStyle(purple);
    }
    &.orange {
      @include colorStyle(orange);
    }
    &.yellow {
      @include colorStyle(yellow);
    }
    &.white {
      @include colorStyle(white);
    }

    &.transparent {
      padding: 19px 0;
      border: none !important;
      background-color: transparent !important;
    }
    &.video-icon {
      position: relative;
      padding-left: 58px;
      .button__icon {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 15px;
        margin: auto 0;
        width: 30px;
        height: 30px;
        border-radius: 15px;
        background-color: gold;

        svg {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          margin: auto;
          width: 13px;
          transform: translateX(1px);
        }
      }
    }
  }
}
