@mixin checklist-item($size, $answer, $color: "blue", $background-color: "white") {
  content: "";
  width: $size;
  height: $size;
  background-image: url("../../images/checklist-#{$answer}-#{$color}.svg");
  background-size: calc(#{$size} * 0.625);
  background-position: center center;
  background-repeat: no-repeat;
  display: inline-block;
  background-color: $background-color;
  border-radius: calc(#{$size} * 0.5);
  position: absolute;
  top: 2px;
  left: 0;
}

@mixin gradient($color-name, $color-value) {
  &.has-#{$color-name}-gradient {
    background: linear-gradient(180deg, $color-value 0%, rgba(255, 255, 255, 0) 100%);
  }
}
