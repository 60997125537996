@import "../settings";

.testimonials__carousel {
  .timer__container {
    margin: 2rem 1rem 1rem 1rem;

    .testimonials__timer {
      bottom: 0;
      left: 0;
      max-width: 50%;
      height: 4px;
      border-radius: 0.5rem;
      overflow: hidden;
      margin: 0 2.5rem;
      background: $grey300;

      @include breakpoint(small only) {
        max-width: 40%;
      }
      .progress-bar {
        height: 100%;
        border-radius: 0.5rem;
        overflow: hidden;
        background-color: $grey700;
      }
    }
    .arrow__left,
    .arrow__right {
      img {
        width: 24px;
        height: 24px;
      }
    }

    .arrow__left {
      left: 0;
    }
    .arrow__right {
      right: 0;
    }
  }
  @keyframes progressBar {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }

  .testimonials__element {
    background: $white;
    box-shadow: 0px 12px 24px 1px rgba(201, 213, 226, 0.33);
    border-radius: 40px;
    padding: 6rem 2.5rem 2.5rem;
    transition: all 0.3s ease;
    width: 500px;
    max-width: 500px;

    @include breakpoint(small only) {
      width: 100%;
      max-width: 100%;
    }
    &:not(.active) {
      position: absolute;
      opacity: 0;
      top: 0;
    }
    &.active {
      position: relative;
      opacity: 1;
    }

    .block__content {
      position: relative;
      &:before {
        position: absolute;
        content: "";
        top: -40px;
        left: 0;
        background-image: url("../images/icons/testimonials-carousel-quote.svg");
        background-repeat: no-repeat;
        background-size: 40px;
        width: 40px;
        height: 40px;
      }
      h3 {
        margin-bottom: 1rem;
      }
      .quote {
        font-size: 16px;
        font-weight: 500;
        line-height: 26px;
      }
    }
    .block__image {
      position: absolute;
      top: -67.5px;
      right: 172.5px;
      object-fit: cover;
      width: 135px;
      height: 135px;
      border-radius: 135px;
      overflow: hidden;
      background: white;
      height: 135px;
      filter: drop-shadow(0px 12px 24px rgba(201, 213, 226, 0.33));

      img {
        object-fit: cover !important;
        object-position: center !important;
      }

      @include breakpoint(small only) {
        right: 33.33%;
      }
    }
  }
}
