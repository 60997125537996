@import "settings";
@import "../../../../node_modules/foundation-sites/scss/foundation";
@import "style";
@import "../scss/components/header";
@import "../scss/components/footer";

footer {
  &[data-country="ca"] .us-only {
    display: none;
  }

  .main-footer {
    & > .grid-container {
      border: none;
    }
  }

  .legal-footer {
    background-color: $white;
  }
}

main#main-content {
  padding-top: 81px;
  button:has(svg.svg-inline--fa.fa-xmark) {
    top: 0;
  }
}
