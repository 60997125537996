@import "../settings";
@import "../elements/home-hero-animation";
@import "../elements/wellevate-hero-form";
@import "../elements/testimonials-element";

.component__hero {
  &.standard-padding:not([data-padding]) {
    @include breakpoint(small only) {
      padding-top: 2rem !important;
    }
  }
  &.half-standard-padding {
    @include breakpoint(small only) {
      padding: 3rem 1rem 2rem;
    }
  }
  .toggles {
    margin-left: -0.33rem;
    .toggle {
      transition: all 0.3s ease;
      background-color: $white;
      color: $coal2;
      padding: 0.5rem 1rem;
      border-radius: 2rem;
      margin: 0.5rem;
      border: 1px solid white;
      display: inline-block;
      width: auto;
      @include breakpoint(medium down) {
        font-size: 14px;
        padding: 0.25rem 1rem;
      }
      &:hover {
        border: 1px solid $coal2;
      }
      &.is-current {
        background-color: $coal2;
        color: $white;
        font-weight: 800;
        border: 1px solid $coal2;
      }
    }
  }
  :has(.component__hero-images) {
    .toggle {
      @include breakpoint(medium only) {
        margin-bottom: 2rem;
      }
    }
  }
  .hero__tag,
  .bucket__tag {
    color: $coal2;
    font-weight: 600;
    display: block;
    margin-bottom: 10px;
    margin-top: 0;

    .has-text-color & {
      color: inherit !important;
    }

    @include breakpoint(small only) {
      max-width: 75%;
    }

    &:after {
      content: "";
      width: 40px;
      height: 2px;
      background-color: $coal2;
      display: inline-block;
      border-radius: 2px;
      margin-left: 12px;
      margin-bottom: 6px;
    }
  }

  &.has-text-color {
    &.has-white-color {
      .hero__tag {
        color: $white;
        &:after {
          background-color: $white;
        }
      }
    }
  }
  &.pill {
    padding: 0 1em 3em;
  }
  &.full {
    &:not(.as__block) {
      padding: 0 1em 0;
      @include breakpoint(small only) {
        padding: 3rem 1rem 0;
      }
    }
    img {
      width: 100%;
      transition: transform 0.3s;
      transform-origin: 50% 50%;
      backface-visibility: hidden;
      height: 100%;
      object-fit: cover;
      object-position: 0 50%;
      @include breakpoint(medium down) {
        object-fit: contain;
        object-position: initial;
      }
    }
  }

  &.as__block {
    position: relative;

    &.has-coal-2-color {
      .hero__tag:after {
        background-color: $coal2;
      }
    }
    &.has-white-color {
      .hero__tag:after {
        background-color: $white;
      }
    }
  }

  .bucket__title {
    margin-bottom: 2rem;
    @include breakpoint(small only) {
      br {
        display: none;
      }
    }
  }
  .element__bucket {
    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
    }
    .button-group {
      .button {
        margin-right: 0;
      }
      .button:last-child:not(:first-child) {
        @include breakpoint(medium) {
          margin-left: 1rem;
        }
      }
      .button:last-child {
        @include breakpoint(small only) {
          margin-bottom: 0rem;
        }
      }
    }
  }

  form.search-form {
    max-width: 350px;
    display: inline-block;
    width: 100%;
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
      display: none;
    }
  }

  .hero__image {
    position: relative;
    .video__button {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      background-color: $white;
      box-shadow: $shadowdark40;
      height: 60px;
      border-radius: 30px;
      padding: 1rem 1rem 1rem 0.5rem;
      left: 1rem;
      bottom: 1rem;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:after {
        content: "Watch video";
        color: $coal2;
        font-size: 18px;
        font-weight: 800;
      }
      &:before {
        content: "";
        background-image: url("../images/icons/play-icon.svg");
        background-repeat: no-repeat;
        background-position: 11px;
        background-size: 16px;
        width: 36px;
        height: 36px;
        background-color: $white;
        display: inline-block;
        border-radius: 18px;
        margin-right: 0.5rem;
        transition: background-color 0.3s ease, background-image 0.3s ease;
      }
      &:hover {
        &:before {
          background-color: $forest5;
          background-image: url("../images/icons/play-icon-green-900.svg");
        }
      }
    }
  }
  .component__hero-images {
    .has-video {
      height: fit-content;
      border-radius: 24px;
      overflow: hidden;
    }
  }

  .hero__countdown {
    border-radius: 1rem;
    text-align: center;
    margin-top: 1rem;

    .countdown-container {
      padding: 0rem 2rem 2.5rem;
      margin-top: 1rem;
      .expired_message {
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
        @include breakpoint(small only) {
          font-size: 20px;
          line-height: 28px;
        }
      }
    }

    .countdown_title {
      margin-top: 1rem;
    }
    .grid-x {
      max-width: auto;
      margin: 0 auto;
    }
    .time_section {
      border-radius: 1rem;
      padding: 1rem;
      margin-left: 0.25rem;
      margin-right: 0.25rem;
      font-size: 16px;
      line-height: 28px;
      &.medium-3,
      &.medium-4 {
        width: 100%;
        @include breakpoint(medium) {
          width: calc(25% - 0.5rem);
        }
      }
      @include breakpoint(small only) {
        margin: 0.5rem 0;
      }
      span {
        font-size: 3.5rem;
        line-height: 3.5rem;
        font-weight: 500;
        display: block;
        @include breakpoint(small only) {
          font-size: 2rem;
          line-height: 2.5rem;
          font-weight: 60;
        }
      }
    }
  }
}
.component__hero {
  .element__bucket-content {
    font-weight: 400;
  }
  @include breakpoint(small only) {
    & > .grid-container {
      margin-top: 81px;
    }
  }

  &.has-search {
    .component__hero-content .bucket__title {
      margin-bottom: 4rem;
    }
    &.v2 {
      .component__hero-content .bucket__title {
        margin-bottom: rem-calc(40);
        font-weight: 700;
      }
    }
  }
  &.as__block.has-search.no__image {
    .search-form:not(.v2) {
      max-width: 600px;
      width: 100%;
    }
  }
  .large-12 {
    .search-form:not(.v2) {
      max-width: 600px;
      width: 100%;
    }
  }
  .large-6 {
    &.component__hero-content {
      @include breakpoint(large) {
        padding-right: 4rem;
      }
    }
    &.component__hero-images {
      @include breakpoint(medium down) {
        margin-top: 2rem;
      }
      @include breakpoint(large) {
        padding-left: 4rem;
      }
    }
  }

  &.v2 {
    &.no__image:not(.has-form) {
      .component__hero-content {
        padding-right: 0;
        .bucket__tag {
          justify-content: center;
          @include breakpoint(small only) {
            max-width: 100%;
          }
        }
      }
      p:not(.element__section--subtitle) {
        // width: 415px;
        max-width: 100%;
        margin: 0 auto rem-calc(40);
      }
    }
    &.has-search {
      @include breakpoint(medium down) {
        & > .grid-container {
          margin-top: 81px;
        }
      }
      &.has__media {
        @include breakpoint(large) {
          .search-form {
            form {
              margin: unset;
            }
          }
        }
        @include breakpoint(medium down) {
          .component__hero-images {
            display: none;
          }
        }
      }
      .button-group {
        margin-top: 0;
      }
    }
    &.has-form {
      .full {
        max-height: unset;
      }
      .large-6:has(.component__iterable-form) {
        @include breakpoint(large) {
          padding-left: 4rem;
        }
      }
      .component__iterable-form {
        position: relative;
        z-index: 2;

        &.labs,
        &.generic {
          width: unset;
          box-shadow: unset;
          border-radius: 16px;
          padding: 2rem;

          .element__bucket {
            @include breakpoint(medium down) {
              text-align: left;
            }
            .bucket__title {
              font-family: $sofia;
              margin-bottom: 1rem;
              font-weight: 700 !important;
            }
            .element__bucket-content {
              margin-bottom: 2rem;
            }
          }
        }
      }
    }
    &:not(.no__image) {
      .component__hero-content {
        @include breakpoint(large) {
          padding-right: 0;
        }
      }
      .component__hero-images {
        .hero__image {
          &.full {
            max-height: unset;
          }
        }
      }
    }
    .scroll-instructions__container {
      margin-top: 2.5rem;
    }
    &.has__video {
      .hero__image {
        position: relative;
      }
      .video__button {
        border: 1px solid $white;
        padding: 1rem 1.5rem 1rem 0.75rem;
        background-color: rgba(46, 58, 71, 0.4);
        backdrop-filter: blur(12px);
        transition: all 0.3s ease;
        z-index: 3;
        @include breakpoint(small only) {
          height: 48px;
          padding: 1rem 1rem 1rem 0.5rem;
        }
        &:hover {
          background: rgba(46, 58, 71, 0.7);
        }

        &:before {
          background-image: url("../images/icons/play-icon-white.svg");
          background-color: transparent;
          border: 1px solid $white;
          margin-right: 16px;

          @include breakpoint(small only) {
            width: 32px;
            height: 32px;
            background-size: 11px;
          }
        }
        &:after {
          content: "Play video";
          color: $white;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;

          @include breakpoint(small only) {
            font-size: 16px;
            line-height: 26px;
          }
        }
      }
    }
  }
  &.v2 {
    .bucket__tag {
      text-transform: uppercase;
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      padding-bottom: 1.5rem;
      display: flex;
      align-items: center;
      @include breakpoint(small) {
        padding-bottom: 1rem;
      }
      &:after {
        display: none;
      }
    }
    .tag__icon {
      img {
        width: 40px !important;
        height: 40px !important;
        margin-right: 1rem;
        margin-bottom: 0.4rem;
      }
    }
    &:not(.no__image) {
      .large-6 {
        &.component__hero-content {
          @include breakpoint(large) {
            padding-right: 6.5rem;
          }
        }
        &.component__hero-images {
          @include breakpoint(medium down) {
            margin-top: 2rem;
          }
          @include breakpoint(large) {
            padding-left: 0rem;
          }
        }
      }
    }
    .element__bucket {
      .bucket__title {
        font-weight: 700;
      }
      .element__section--subtitle {
        p {
          font-weight: 400;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
        @include breakpoint(small) {
          padding-bottom: 1rem;
        }
      }
    }

    @include breakpoint(large) {
      &.standard-padding {
        &:not(.no__image) {
          // padding: 6.5rem 1rem;
        }
      }
    }

    .component__hero-images {
      &:has(.testimonials__element) {
        @include breakpoint(medium down) {
          margin-top: 6rem !important;
        }
        @include breakpoint(small only) {
          .hero__image {
            justify-content: center !important;
          }
        }
      }
    }
  }
}

.hero__image {
  display: flex;
  align-items: center;
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  &.full {
    max-height: 525px;
    height: auto;
  }

  &.half-pill {
    height: 265px;
    border-radius: 265px 265px 0 0;
    width: 100%;
    overflow: hidden;
    margin-top: 2rem;

    @include breakpoint(small only) {
      margin-top: 0;
      width: 250px;
      height: 250px;
      border-radius: 125px;
      margin-bottom: 2rem;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &.rectangle {
    border-radius: 24px;
    width: 100%;
    overflow: hidden;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      object-position: 0 50%;
    }
  }

  @include breakpoint(small only) {
    &.rectangle {
      max-width: 100%;
      max-height: 200px;
    }

    &.circle {
      width: 250px;
      height: 250px;
      border-radius: 125px;
    }

    &.pill {
      border-radius: 0 0 160px 160px;
    }

    &.full {
      background-size: auto 100%;
      width: 100%;
    }
  }

  @include breakpoint(medium up) {
    &.rectangle {
      height: 300px;
      width: 100%;
    }

    &.circle {
      width: 300px;
      height: 300px;
      img {
        border-radius: 150px;
        overflow: hidden;
        max-width: 100%;
        min-width: 300px;
        height: 100%;
      }
    }

    &.pill {
      border-radius: 0 0 260px 260px;
    }
  }

  @include breakpoint(small only) {
    &.circle {
      width: auto;
      height: auto;
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-content: center;
      margin: 2rem auto 0;
      img {
        width: 250px;
        height: 250px;
        border-radius: 125px;
        overflow: hidden;
        object-fit: cover;
      }
      .hero__image-secondary {
        width: 150px;
        height: 150px;
      }
    }
  }
  .hero__image-secondary {
    width: 160px;
    height: 160px;
    border-radius: 80px;
    background-color: $coal4;
    display: inline-block;
    position: absolute;
    bottom: -1.25rem;
    top: auto;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    &.right-side {
      left: auto;
      right: 0.5rem;
    }
    &.left-side {
      left: -1.5rem;
      right: auto;
    }
  }

  .home__video {
    box-shadow: 0px 8px 32px rgba(65, 91, 115, 0.24);
    width: 62px;
    height: 62px;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 32px;
    position: absolute;
    top: auto;
    left: 1rem;
    bottom: 5.5rem;
    z-index: 2;
    border: 2px solid transparent;
    outline: none !important;
    cursor: pointer;
    font-size: 22px;

    svg,
    svg path {
      transition: all 0.3s ease;
      fill: $forest2;
    }

    &:hover,
    &:focus {
      border: 2px solid $berry2;
      svg path {
        fill: $berry2;
      }
    }
  }
}

.align-middle {
  @include breakpoint(small only) {
    .hero__tag {
      max-width: 100%;
    }
  }
}

.article__header-meta {
  .fact-button {
    svg {
      width: 18px;
    }
  }
  @include breakpoint(medium) {
    padding-left: 4rem;
  }
  @include breakpoint(small only) {
    padding-top: 4rem;
    .fact-button {
      margin-bottom: 2rem;
    }
  }
  .article__header-author {
    margin-top: 30px;

    a {
      transition: color 0.3s ease;
      text-decoration: underline;
    }

    @media (max-width: 767px) {
      width: 100%;
      flex-basis: auto;
      max-width: 100%;
      padding-right: 0;
      margin-top: 15px;
    }
  }

  .article__header-reviewer {
    margin-top: 15px;

    @media (max-width: 767px) {
      width: 100%;
      flex-basis: auto;
      max-width: 100%;
      padding-right: 0;
      margin-top: 10px;
    }
  }

  .article__header-name {
    width: calc(100% - 60px);
    max-width: calc(100% - 60px);
    flex-basis: calc(100% - 60px);
    font-size: 16px;
    line-height: 24px;

    .article__header-label {
      font-weight: 400;
      display: block;

      a {
        i {
          transition: opacity 0.3s ease;
          color: $coal3;
          font-weight: 400;
          opacity: 0.5;
        }

        &:hover {
          i {
            opacity: 1;
          }
        }
      }
    }

    @media (max-width: 1023px) {
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
    }

    @media (max-width: 767px) {
      a {
        display: inline-block;
      }
    }
  }
}

//blog hero

.article__modifications {
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  padding: 2.5rem 1rem;

  @media (max-width: 1023px) {
    margin-bottom: 0;
  }

  .article__header {
    @media (max-width: 1023px) {
      background-color: $white;
      padding-bottom: 0;
    }

    .article__header-content {
      padding-right: 80px;

      @media (max-width: 1023px) {
        padding: 0 30px 40px;
      }
    }

    @media (max-width: 1023px) {
      display: block;
      padding: 0;
    }

    .article__header-tag {
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
      text-transform: uppercase;
      color: $coal3;
    }

    .article__header-title {
      margin-top: 10px;
      font-size: 48px;
      line-height: 58px;
      font-weight: 400;

      strong {
        font-weight: 900;
      }

      span {
        font-weight: 400;
        color: $greenery;
      }

      @media (max-width: 1023px) {
        font-size: 38px;
        line-height: 46px;
      }
    }
  }

  .article__header-avatar {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: inline-block;
    position: relative;

    &:after {
      content: "";
      width: 68px;
      height: 68px;
      position: absolute;
      background-color: transparent;
      border: 2px solid $forest2;
      top: -4px;
      right: -4px;
      left: -4px;
      bottom: -4px;
      display: inline-block;
      border-radius: 47px;
    }
  }

  .article__header-meta {
    @include breakpoint(medium) {
      padding-left: 4rem;
    }
    @include breakpoint(small only) {
      padding-top: 2rem;
    }
    .article__header-author {
      margin-top: 30px;

      a {
        transition: color 0.3s ease;
        text-decoration: underline;
        color: $coal2;

        &:hover {
          color: $berry2;
        }
      }

      @media (max-width: 1023px) {
        width: calc(50% - 80px);
        max-width: calc(50% - 80px);
        margin-top: 0;
        padding-right: 30px;
      }

      @media (max-width: 767px) {
        width: 100%;
        flex-basis: auto;
        max-width: 100%;
        padding-right: 0;
        margin-top: 15px;
      }
    }

    .article__header-reviewer {
      margin-top: 15px;

      @media (max-width: 767px) {
        width: 100%;
        flex-basis: auto;
        max-width: 100%;
        padding-right: 0;
        margin-top: 10px;
      }
    }

    .article__header-name {
      width: calc(100% - 60px);
      max-width: calc(100% - 60px);
      flex-basis: calc(100% - 60px);
      font-size: 16px;
      line-height: 24px;

      .article__header-label {
        font-weight: 400;
        display: block;

        a {
          i {
            transition: opacity 0.3s ease;
            color: $coal3;
            font-weight: 400;
            opacity: 0.5;
          }

          &:hover {
            i {
              opacity: 1;
            }
          }
        }
      }

      @media (max-width: 1023px) {
        width: 100%;
        max-width: 100%;
        flex-basis: 100%;
      }

      @media (max-width: 767px) {
        a {
          display: inline-block;
        }
      }
    }
  }

  .article__post-date {
    color: $coal2;
    margin-top: 20px;

    &.updated {
      span {
        background-color: $coal2;
        border-radius: 4px;
        font-size: 11px;
        color: $white;
        font-weight: 800;
        padding: 2px 6px 3px;
        margin-right: 8px;
        text-transform: uppercase;
      }
    }
  }

  .wrapper {
    max-width: 1200px;
    padding: 0 36px;
  }

  .fact__button {
    transition: color 0.3s ease, background-color 0.3s ease;
    padding: 8px 20px 9px;
    border: 2px solid $greenery;
    background-color: transparent;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $greenery;
    border-radius: 4px;
    text-align: center;
    display: inline-block;
    @include breakpoint(small only) {
      margin-top: 4rem;
      margin-bottom: 2rem;
    }

    i {
      margin-right: 5px;
    }

    &:hover {
      background-color: $greenery;
      color: $white;
    }

    @media (max-width: 1023px) {
      width: 180px;
      flex-basis: 180px;
      max-width: 180px;
      order: 3;
    }

    @media (max-width: 767px) {
      flex-basis: auto;
      order: initial;
      margin-bottom: 10px;
    }
  }
}

.blog__breadcrumbs {
  padding: 0 0 1rem 0;

  .blog__breadcrumb {
    transition: color 0.3s ease;
    display: inline-block;
    position: relative;
    color: $coal2;
    &.has__link {
      &:hover {
        color: $forest2;
      }
    }
    &:not(:last-child) {
      &.blog__breadcrumb-category {
        &:after {
          @media (max-width: 767px) {
            display: none;
          }
        }
      }
      &:after {
        content: "\f054";
        font-family: "Font Awesome 5 Pro";
        font-size: 12px;
        padding-left: 5px;
        padding-right: 5px;
        font-weight: 400;
      }
    }
    &.blog__breadcrumb-title {
      @media (max-width: 767px) {
        display: none;
      }
    }
  }
}

// HOME HERO

#home-hero {
  .element__bucket {
    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
    }
    .button-group {
      flex-direction: column;
      align-items: flex-start;
      .button {
        text-align: left;
      }

      @include breakpoint(medium only) {
        flex-direction: row;
      }
      .button:first-child {
        margin-bottom: 1rem;
        @include breakpoint(small only) {
          margin-bottom: 0;
        }
      }
      .button:last-child {
        margin-left: 0;
        @include breakpoint(small only) {
          margin-bottom: 2rem;
        }
      }
    }
  }
  .hero__image {
    position: relative;

    @include breakpoint(large) {
      max-width: 450px;
      margin-left: auto;
      margin-right: 107px;
      margin-bottom: 85px;
    }
    @include breakpoint(medium) {
      max-width: 400px;
      margin-left: auto;
      margin-right: 65px;
      margin-bottom: 45px;
    }
    @include breakpoint(medium down) {
      margin: 0 auto;
      max-width: 100%;
      width: 100%;
      height: auto;
    }
    .primary__image {
      overflow: hidden;
      border-radius: 24px;
      @include breakpoint(medium down) {
        display: none;
      }
      & > img {
        width: 100%;
        transition: transform 0.3s;
        transform-origin: 50% 50%;
        backface-visibility: hidden;
        height: 100%;
        object-fit: cover;
        object-position: 0 50%;
      }
    }
    .secondary__image {
      position: absolute;
      bottom: -107.45px;
      right: -107.45px;
      width: 214.9px;
      height: 392.7px;
      z-index: 2;
      @include breakpoint(medium) {
        width: auto;
        height: auto;
        right: -65px;
        bottom: -70px;
      }
      @include breakpoint(medium down) {
        position: relative;
        left: auto;
        right: auto;
        margin: 0 auto;
        bottom: 0;
      }
      img {
        box-shadow: $shadowlight60;
        border-radius: 33.93px;
        @include breakpoint(large) {
          width: 214.9px;
          height: 392.7px;
        }
        @include breakpoint(medium) {
          width: 185px;
          height: 335px;
        }
        @include breakpoint(medium down) {
          width: 214.9px;
          height: 392.7px;
        }
      }
    }
  }
}
// Testimonial Hero section
.testimonials__quotes {
  max-width: 495px;
  position: relative;
  height: 390px;

  @media (max-width: 1023px) {
    display: none;
  }

  @media (max-width: 414px) {
    height: 325px;
    display: block;
  }

  .testimonials__block.active {
    opacity: 1;
  }

  .testimonials__block {
    position: absolute;
    right: 0;
    opacity: 0;
    transition: opacity 0.3s ease, transform 1s ease;

    @media (max-width: 414px) {
      width: 100%;
    }

    .block__content {
      position: absolute;
      display: inline-block;
      max-width: 450px;
      background: $white;
      box-shadow: 0px 10px 20px rgba(147, 147, 147, 0.15);
      border-radius: 24px;
      padding: 24px;
      z-index: 1;
      bottom: -150px;
      left: -165px;

      @media (max-width: 414px) {
        max-width: 110%;
        width: 110%;
        padding: 20px 17px 17px 17px;
        bottom: -80px;
        left: -5%;
        margin: 0 auto;
      }

      .quote {
        margin: 0;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;

        @media (max-width: 414px) {
          font-size: 16px;
          line-height: 20px;
        }
      }

      .quotee {
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: #415b73;
        margin: 16px 0 0 0;

        &:before {
          content: "";
          width: 10px;
          height: 1px;
          background-color: #415b73;
          display: inline-block;
          border-radius: 2px;
          margin: 0 5px 5px 0;
          padding-right: 5px;

          @media (max-width: 414px) {
            width: 8px;
            height: 1px;
            margin: 0 5px 4px 0;
            padding-right: 5px;
          }
        }

        @media (max-width: 414px) {
          font-size: 14px;
          line-height: 26px;
        }
      }
    }
    .block__image {
      border-radius: 24px;
      width: 325px;
      height: 250px;
      object-fit: cover;

      @media (max-width: 414px) {
        width: 100%;
        height: 240px;
        margin: 0 auto;
      }
    }
  }
}

.component__hero-wrapper {
  &.has-announcement {
    display: flex;
    flex-direction: column;
    position: relative;

    @include breakpoint(small only) {
      flex-direction: column-reverse;
    }
    .component__hero.standard-padding {
      @media (max-width: 1100px) and (min-width: 768px) {
        padding-top: 6.5rem;
      }
    }
  }
  .announcement__bar {
    position: relative;
    font-size: 16px;
    line-height: 24px;
    padding: 1rem 1rem;

    .announcement__bar-content {
      max-width: 100%;
    }

    &.light {
      background-color: $forest5;
      color: $coal2;
    }
    &.dark {
      background-color: $forest1;
      color: $white;
    }

    @include breakpoint(medium) {
      .grid-container {
        min-height: 49px;
      }
    }
    @include breakpoint(small only) {
      padding: 2rem 1rem 1rem;
      &.announcement__bar {
        padding: 2rem 1rem;
      }
    }

    .announcement__bar-button {
      @include breakpoint(large) {
        padding-left: 1rem;
      }

      .button {
        font-size: 16px;
        text-decoration: none;

        &:hover {
          text-decoration: none;
        }
        svg {
          height: 12px;
          width: 14px;
        }
      }
    }
  }
  &:has(.no-nav),
  &.has-announcement {
    #partner-logo {
      padding: 2.5rem 2rem 2.5rem;
    }
  }
  #partner-logo {
    @include breakpoint(medium up) {
      padding: 7rem 2rem 2.5rem;
    }
    @include breakpoint(small only) {
      padding: 9rem 2rem 2.5rem;
    }

    .container {
      display: grid;
      justify-content: center;
      align-items: center;

      .partners_logo {
        order: 1;
        .p__logo {
          max-height: 75px;
        }
      }
      .logo__divider {
        display: inline-block;
        height: 75px;
        width: 2px;
        background-color: $coal4;
        margin: 0 2rem;
        order: 2;
        @include breakpoint(small only) {
          width: 192px;
          height: 2px;
          margin: 1.5rem 0;
        }
      }
      .fullscript_logo {
        order: 3;
      }
    }
    &:not(.has__cobranding) {
      .container {
        grid-template-columns: fit-content(300px);
      }
    }
    &.has__cobranding {
      .container {
        grid-template-columns: fit-content(300px) 66px fit-content(182px);
        @include breakpoint(small only) {
          grid-template-columns: fit-content(300px);
          justify-items: center;
        }
      }
    }
    &.partner__right {
      .container {
        grid-template-columns: fit-content(182px) 66px fit-content(300px);
        @include breakpoint(small only) {
          grid-template-columns: fit-content(300px);
          justify-items: center;
        }
      }
      .partners_logo {
        order: 3;
      }
      .fullscript_logo {
        order: 1;
      }
    }
  }
}

#software-hero .element__bucket {
  margin-bottom: 2rem;
}

// Gutenberg specific

.component__hero {
  .align-center {
    .button-group {
      @include breakpoint(medium) {
        display: inline-block;
      }
    }
  }
  :has(.component__hero-images) {
    .button-group {
      @include breakpoint(medium only) {
        margin-bottom: 2rem;
      }
    }
  }
}

.has-form {
  @include breakpoint(small only) {
    &:after {
      content: "";
      width: 100%;
      height: 500px;
      background-color: #fff;
      position: absolute;
      display: inline-block;
      top: auto;
      right: 0;
      bottom: -2px;
      left: 0;
      z-index: 1;
    }
  }
}

#downloads-hero {
  @include breakpoint(medium) {
    padding: 5rem 1rem;
  }
  @include breakpoint(small) {
    padding: 3rem 1rem;
  }
  .hero__image {
    @include breakpoint(small) {
      justify-content: center;
      max-height: none;
    }
  }
  .hero__form {
    min-height: 400px;
  }
}

.component__hero.as__block {
  .hero__form-container {
    min-height: 1px;
    @include breakpoint(small only) {
      margin: 0 auto;
    }
  }
}
.hero__form-container {
  img {
    transform: rotate(-15deg);
  }
  @include breakpoint(large) {
    margin-left: auto;
    min-height: 400px;

    img {
      position: absolute;
      z-index: 1;
      left: -135px;
      box-shadow: $shadowlight20;
      top: 0;
      bottom: 0;
      margin: auto 0;
      height: 300px;
    }
  }
  @include breakpoint(medium only) {
    img {
      display: none;
    }
    padding-top: 40px;
  }
  @include breakpoint(small only) {
    img {
      transform: translateY(40px) rotate(-15deg);
      margin-bottom: 120px;
      height: 200px;
    }
  }

  .hero__form {
    position: relative;
    z-index: 2;
    width: 385px;
    padding: 2rem;
    max-width: 90%;
    margin: 0 auto;
    @include breakpoint(medium only) {
      width: 340px;
    }
    @media (min-width: 640px) and (max-width: 767px) {
      width: 300px;
    }
    @include breakpoint(small only) {
      margin-top: 25px;
    }
    form {
      max-width: 100%;
      #FirstName,
      #Email,
      #LastName,
      #Practitioner_Type__c,
      select,
      input {
        font-family: $mulish !important;
        background-color: #f6f9fc;
        box-shadow: none;
        border: 1px solid #f0f5fc;
        border-bottom: 1px solid #415b73;
        border-radius: 4px 4px 0 0;
        color: $primary !important;
      }
      @include breakpoint(small only) {
        #Email {
          padding: 1.5rem 1rem !important;
        }
        #Practitioner_Type__c {
          font-size: 1pc;
          color: $primary !important;
        }
      }

      select::placeholder,
      input::placeholder {
        color: $primary !important;
      }

      .mktoButton {
        font-family: $mulish !important;
        border: 1px solid transparent;
        padding: 16px 24px !important;
        @include breakpoint(small only) {
          width: 100% !important;
        }
        &:hover {
          cursor: pointer;
          background-color: transparent !important;
          border: 1px solid $forest2;
          color: $forest2;
        }
      }
      .mktoButtonRow {
        text-align: left !important;
      }
      .mktoError {
        position: static !important;
        .mktoErrorArrowWrap {
          .mktoErrorArrow {
            display: none !important;
            background: transparent !important;
            box-shadow: none !important;
            max-width: 100% !important;
          }
        }
        .mktoErrorMsg {
          box-shadow: none !important;
          font-weight: 400;
          max-width: 100% !important;
          background: transparent !important;
          font-family: $mulish !important;
          padding: 1rem !important;
          padding-top: 4rem !important;
          margin-top: 15px !important;
          border-top: none !important;
          background-color: $white !important;
          border-right: 1px solid #f0f5fc !important;
          border-left: 1px solid #f0f5fc !important;
          border-bottom: 1px solid #f0f5fc !important;
          color: #36485c !important;
        }
      }
    }
  }
}
@mixin animate-item($direction, $distance, $delay) {
  $direction: to-lower-case($direction);

  $animation-name: unquote("animate-#{$direction}-#{$distance}");

  @keyframes #{$animation-name} {
    0% {
      opacity: 0;
      @if $direction == "up" {
        transform: translateY($distance);
      } @else if $direction == "down" {
        transform: translateY(-$distance);
      } @else if $direction == "left" {
        transform: translateX($distance);
      } @else if $direction == "right" {
        transform: translateX(-$distance);
      }
    }
    10% {
      opacity: 1;
      transform: translateX(0) translateY(0);
    }
    40% {
      opacity: 1;
      transform: translateX(0) translateY(0);
    }
    50% {
      opacity: 0;
      @if $direction == "up" {
        transform: translateY(-$distance);
      } @else if $direction == "down" {
        transform: translateY($distance);
      } @else if $direction == "left" {
        transform: translateX(-$distance);
      } @else if $direction == "right" {
        transform: translateX($distance);
      }
    }
  }

  animation-name: $animation-name;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-delay: $delay;
}

.component__hero .component__hero-animation {
  height: 295px;
  width: 335px;
  max-width: calc(100vw - 2rem);

  @include breakpoint(medium) {
    height: 600px;
    max-width: 100%;
    width: 550px;
    margin-bottom: 80px;
  }
  .animation-frame {
    .animation-object {
      position: absolute;
      display: inline-block;
      border-radius: 25px;
      background-size: cover !important;
      background-repeat: no-repeat !important;
      background-position: center center !important;
      opacity: 0;
    }
    &.animation-a {
      &[data-frame="0"] {
        .animation-object {
          &:nth-child(1) {
            background: $orange400;
            z-index: 3;
            @include breakpoint(small only) {
              @include animate-item("up", 20px, 0.25s);
              top: 45px;
              left: 0;
              width: 157px;
              height: 115px;
            }
            @include breakpoint(medium) {
              @include animate-item("down", 20px, 0.25s);
              top: 0;
              left: 0;
              bottom: 0;
              right: auto;
              margin: auto 0;
              width: 310px;
              height: 235px;
            }
          }
          &:nth-child(2) {
            background: $purple400;
            z-index: 2;
            @include breakpoint(small only) {
              @include animate-item("up", 20px, 0.75s);
              top: 0;
              right: 0;
              width: 157px;
              height: 236px;
            }
            @include breakpoint(medium) {
              @include animate-item("down", 20px, 0.5s);
              bottom: -2rem;
              left: 111px;
              width: 172px;
              height: 172px;
            }
          }
          &:nth-child(3) {
            background: $green400;
            z-index: 3;
            @include breakpoint(small only) {
              @include animate-item("up", 20px, 0.5s);
              width: 119px;
              height: 117px;
              bottom: 0;
              left: 38px;
            }
            @include breakpoint(medium) {
              @include animate-item("down", 20px, 0.75s);
              top: 0px;
              right: 0px;
              width: 414px;
              height: 596px;
              z-index: 1;
            }
          }
        }
      }
      &[data-frame="1"] {
        .animation-object {
          &:nth-child(1) {
            background: $orange400;
            z-index: 1;
            @include breakpoint(small only) {
              @include animate-item("up", 20px, 5.25s);
              top: 0;
              left: 0;
              width: 158px;
              height: 177px;
            }
            @include breakpoint(medium) {
              @include animate-item("down", 20px, 5.25s);
              top: 0;
              left: 0;
              width: 327px;
              height: 490px;
            }
          }
          &:nth-child(2) {
            background: $purple400;
            z-index: 2;
            @include breakpoint(small only) {
              @include animate-item("up", 20px, 5.5s);
              bottom: 0;
              left: 0;
              width: 158px;
              height: 105px;
              margin: auto 0;
            }
            @include breakpoint(medium) {
              @include animate-item("down", 20px, 5.5s);
              bottom: 0;
              right: 0;
              width: 418px;
              height: 278px;
            }
          }
          &:nth-child(3) {
            background: $green400;
            z-index: 3;
            @include breakpoint(small only) {
              @include animate-item("up", 20px, 5.75s);
              width: 158px;
              height: 236px;
              bottom: 0;
              top: 0;
              right: 0;
              margin: auto 0;
            }
            @include breakpoint(medium) {
              @include animate-item("down", 20px, 5.75s);
              top: 78px;
              right: 47px;
              width: 150px;
              height: 225px;
            }
          }
        }
      }
    }

    &.animation-b {
      &[data-frame="0"] {
        .animation-object {
          &:nth-child(1) {
            background: $orange400;
            z-index: 1;
            @include breakpoint(small only) {
              @include animate-item("right", 20px, 0.25s);
              top: 0px;
              left: 0;
              width: 158px;
              height: 237px;
            }
            @include breakpoint(medium) {
              @include animate-item("down", 20px, 0.75s);
              top: 0;
              left: 0;
              width: 300px;
              height: 450px;
            }
          }
          &:nth-child(2) {
            background: $purple400;
            z-index: 2;
            @include breakpoint(small only) {
              @include animate-item("right", 20px, 0.75s);
              top: 32px;
              right: 0;
              width: 158px;
              height: 118px;
            }
            @include breakpoint(medium) {
              @include animate-item("down", 20px, 0.5s);
              bottom: 0rem;
              right: 95px;
              width: 287px;
              height: 220px;
            }
          }
          &:nth-child(3) {
            background: $green400;
            z-index: 3;
            @include breakpoint(small only) {
              @include animate-item("right", 20px, 0.5s);
              bottom: 0;
              right: 0;
              width: 158px;
              height: 118px;
            }
            @include breakpoint(medium) {
              @include animate-item("down", 20px, 0.25s);
              top: 120px;
              right: -2rem;
              bottom: auto;
              left: auto;
              width: 265px;
              height: 200px;
            }
          }
        }
      }
      &[data-frame="1"] {
        .animation-object {
          &:nth-child(1) {
            background: $orange400;
            z-index: 1;
            @include breakpoint(small only) {
              @include animate-item("right", 20px, 5.25s);
              top: 0;
              left: 0;
              bottom: 0;
              margin: auto 0;
              width: 159px;
              height: 159px;
            }
            @include breakpoint(medium) {
              @include animate-item("down", 20px, 5.25s);
              top: 0;
              right: 0;
              width: 340px;
              height: 450px;
            }
          }
          &:nth-child(2) {
            background: $purple400;
            z-index: 2;
            @include breakpoint(small only) {
              @include animate-item("right", 20px, 5.5s);
              right: 0;
              top: 0;
              width: 158px;
              height: 211px;
              margin: auto 0;
            }
            @include breakpoint(medium) {
              @include animate-item("down", 20px, 5.5s);
              bottom: 2rem;
              left: 0;
              width: 408px;
              height: 209px;
            }
          }
        }
      }
    }
  }
}
