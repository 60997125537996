@import "../settings";

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.downward__arrow {
  position: absolute;

  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 2;
  display: inline-block;

  &.medium {
    bottom: -1.75em;
    width: 56px;
    span {
      width: 56px;
      height: 56px;
      border-radius: 56px;
      svg {
        width: 23px;
        height: 26px;
      }
    }
  }
  &.small {
    bottom: -1.25rem;
    width: 40px;
    span {
      width: 40px;
      height: 40px;
      border-radius: 40px;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  &.blue {
    span {
      background-color: $coal1;
    }
  }
  &.green {
    span {
      background-color: $forest2;
    }
  }
  &.animated {
    span {
      animation: pulse 2s infinite;
    }
  }
  &.shift {
    span {
      cursor: pointer;
    }
  }
  span {
    transition: box-shadow 0.3s ease;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    font-weight: 900;
    font-size: 25.6px;
    line-height: 26px;
    position: relative;
    box-shadow: $shadowbutton;
    &:hover {
      box-shadow: $shadowbuttonhover;
    }

    svg path {
      fill: $white;
    }
  }
  // &:before {
  //   font-family: $fa;
  //   content: "\f063";
  //   color: $white;
  //   background-color: $coal1;
  //   width: 56px;
  //   height: 56px;
  //   border-radius: 56px;
  //   text-align: center;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   margin: 0 auto;
  //   font-weight: 900;
  //   font-size: 25.6px;
  //   line-height: 26px;
  //   position: relative;

  //   @media (max-width: 767px) {
  //     top: -28px;
  //   }
  // }
}
