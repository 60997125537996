@import "../settings";

.wellevate-form {
  padding: 1.5rem;
  background-color: $white;
  border-radius: 1rem;
}

.component__cta {
  .wellevate-form {
    margin: 0 auto;
    width: 436px;
    max-width: 100%;
    text-align: center;
    .form__field-footer {
      text-align: center;
      display: block;
      width: 100%;
    }
  }
}
