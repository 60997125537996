@import "../settings";

.search-form.v2 .search__form-container {
  button {
    position: absolute;
    top: 0;
    right: 4px;
    bottom: 0;
    margin: auto 0.5rem auto;
    img {
      width: 20px;
    }
  }
}
