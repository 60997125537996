/************************************************

Stylesheet: Global Stylesheet

*************************************************/

/* Above the fold imports */
@import "../scss/components/header";
@import "../scss/components/components";
@import "../scss/components/hero";
@import "../scss/components/footer";
@import "../scss/components/pagination";
@import "../scss/components/secondary-nav";
@import "../scss/components/scroll-instructions";
@import "../scss/components/downward-arrow";
@import "../scss/components/search-form";
@import "../scss/components/share-buttons";
@import "../scss/components/form";
@import "../scss/elements/buttons";

// container modifiers : start
.relative-container {
  position: relative;
}

article.type-page {
  .entry-content > .grid-container {
    padding: 0;
  }
}

.align-self-center {
  margin-left: auto;
  margin-right: auto;
  &.cell {
    margin-left: auto;
    margin-right: auto;
  }
}

.slim-container {
  @include breakpoint(large) {
    margin-left: auto;
    margin-right: auto;
    max-width: 1000px;
  }
}
.uniform-padding {
  @include breakpoint(small) {
    padding: 1rem; // 16px
  }

  @include breakpoint(medium) {
    padding: 2rem; // 32px
  }

  @include breakpoint(large) {
    padding: 4rem; // 64px
  }
}
.large-uniform-padding {
  @include breakpoint(small) {
    padding: 4rem 1rem; // 64rem 16x
  }

  @include breakpoint(medium up) {
    padding: 6.5rem 1rem; // 104px 16px
  }
}
.next-standard-padding {
  @include breakpoint(small) {
    padding: 2rem 1rem 4rem; // 32px 16px 64px
  }

  @include breakpoint(medium up) {
    padding: 4.5rem 1rem 6.5rem; // 72px 16px 104px
  }
}
.standard-padding:not([data-padding]):not(.in__cards) {
  @include breakpoint(small) {
    padding: 3rem 1rem 3rem; // 48px 16px 48px
  }

  @include breakpoint(medium) {
    padding: 5rem 1rem 4rem; // 80px 16px 64px
  }

  @include breakpoint(large) {
    padding: 9rem 1rem 6.5rem; // 144px 16px 104px
  }
}

.half-standard-padding:not([data-padding]) {
  @include breakpoint(small) {
    padding: 2rem 1rem 2rem; // 32px 16px 32px
  }

  @include breakpoint(medium) {
    padding: 2.5rem 1rem 2rem; // 40px 16px 32px;
  }

  @include breakpoint(large) {
    padding: 4.375rem 1rem 3.25rem; // 70px 16px 52px
  }
}

.standard-padding-top:not([data-padding]) {
  @include breakpoint(small) {
    padding: 4rem 1rem 0; // 64px 16px 0
  }

  @include breakpoint(medium) {
    padding: 5rem 1rem 0; // 80px 16px 0
  }

  @include breakpoint(large) {
    padding: 9rem 1rem 0; // 144px 16px 0
  }
}

.no-padding {
  padding: 2rem 1rem; // 32px 16px
}

.v2.less-padding {
  padding: 3rem 1rem; // 48px 16px
}

.zero-padding {
  padding: 0 1rem;
}

.standard-padding-bottom:not([data-padding]) {
  @include breakpoint(small) {
    padding: 1rem 1rem 4rem; // 16px 16px 64px
  }

  @include breakpoint(medium) {
    padding: 0rem 1rem 64px;
  }

  @include breakpoint(large) {
    padding: 0rem 1rem 6.5rem;
  }
}

.padding-top:not([data-padding]) {
  @include breakpoint(small) {
    padding-top: 1.5rem;
  }

  @include breakpoint(medium) {
    padding-top: 2rem;
  }

  @include breakpoint(large) {
    padding-top: 3rem;
  }
}
.large-padding-top:not([data-padding]) {
  @include breakpoint(small) {
    padding-top: 2rem;
  }

  @include breakpoint(medium) {
    padding-top: 3rem;
  }

  @include breakpoint(large) {
    padding-top: 4rem;
  }
}
.half-padding-top:not([data-padding]) {
  @include breakpoint(small) {
    padding-top: 1.5rem;
  }

  @include breakpoint(medium) {
    padding-top: 2rem;
  }

  @include breakpoint(large) {
    padding-top: 3rem;
  }
}

.half-padding-bottom:not([data-padding]) {
  @include breakpoint(small) {
    padding-bottom: 1.5rem;
    &.cell {
      padding-bottom: 1.5rem;
    }
  }

  @include breakpoint(medium) {
    padding-bottom: 2rem;
    &.cell {
      padding-bottom: 2rem;
    }
  }

  @include breakpoint(large) {
    padding-bottom: 3rem;
    &.cell {
      padding-bottom: 3rem;
    }
  }
}

.standard-padding-mobile:not([data-padding]) {
  @include breakpoint(small) {
    padding: 4rem 1rem 4rem; // 64px 16px 64px
  }
}

.padding-48:not([data-padding]) {
  padding: 3rem 1rem;
}
.padding-96:not([data-padding]) {
  padding: 6rem 1rem;
}

.margin-top {
  @include breakpoint(small) {
    margin-top: 1.5rem;
  }

  @include breakpoint(medium) {
    margin-top: 2rem;
  }

  @include breakpoint(large) {
    margin-top: 3rem;
  }
}

.margin-bottom {
  @include breakpoint(small) {
    margin-bottom: 1.5rem;
  }

  @include breakpoint(medium) {
    margin-bottom: 2rem;
  }

  @include breakpoint(large) {
    margin-bottom: 3rem;
  }
}

.half-margin-top {
  @include breakpoint(small) {
    margin-top: 0.75rem;
  }

  @include breakpoint(medium) {
    margin-top: 1rem;
  }

  @include breakpoint(large) {
    margin-top: 1.5rem;
  }
}

.no-margin-top {
  margin-top: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.cell {
  &.cell-padding {
    padding: 1rem;
  }
  &.rounded {
    border-radius: 18.45px;
  }
}

.overflow-hidden {
  overflow: hidden;
}
.round-50 {
  border-radius: 50px;
  @include breakpoint(small only) {
    border-radius: 24px;
  }
}
.round-24 {
  border-radius: 24px;
}
.round-8 {
  border-radius: 8px;
}

// container modifiers : end

// acf padding

@mixin generate-padding-margin-rules {
  $padding-values: 0, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80, 88, 96, 104, 112, 120, 128, 136, 144;
  $margin-values: 0, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80, 88, 96, 104, 112, 120, 128, 136, 144;

  @each $padding-top in $padding-values {
    @each $padding-bottom in $padding-values {
      $data-padding-value: "#{$padding-top}-#{$padding-bottom}";

      &[data-mobile-padding="#{$data-padding-value}"] {
        @include breakpoint(small only) {
          padding-top: #{$padding-top}px;
          padding-bottom: #{$padding-bottom}px;
        }
      }

      &[data-padding="#{$data-padding-value}"] {
        @include breakpoint(medium) {
          padding-top: #{$padding-top}px;
          padding-bottom: #{$padding-bottom}px;
        }
      }
    }
  }

  @each $margin-top in $margin-values {
    @each $margin-bottom in $margin-values {
      $data-margin-value: "#{$margin-top}-#{$margin-bottom}";

      &[data-mobile-margin="#{$data-margin-value}"] {
        @include breakpoint(small only) {
          margin-top: #{$margin-top}px;
          margin-bottom: #{$margin-bottom}px;
        }
      }

      &[data-margin="#{$data-margin-value}"] {
        @include breakpoint(medium) {
          margin-top: #{$margin-top}px;
          margin-bottom: #{$margin-bottom}px;
        }
      }
    }
  }
}

section:not(.entry-content) {
  @include generate-padding-margin-rules;
  &[data-padding],
  &[data-margin] {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

// text modifiers

table {
  border-collapse: separate;
  border-spacing: 0;
  thead {
    th:first-child {
      border-top-left-radius: 8px;
    }
    th:last-child {
      border-top-right-radius: 8px;
    }
  }
  tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
  }
  tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
  }
}

ul.breadcrumbs,
ol.breadcrumbs {
  li:after {
    font-family: $fa;
  }
}
ul.pills {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: 1.5rem 0rem 2rem -0.25rem;
  display: inline-block;
  align-items: center;
  justify-content: center;
  li {
    display: inline-block;
    padding: 0.25rem 0.5rem;
    margin: 0.25rem;
    border-radius: 16px;
    background-color: $forest5;
    font-size: 14px;
    line-height: 16px;
  }
  &.dark__theme {
    li {
      background-color: $coal3;
      color: $white;
    }
  }
}

.checklist-container {
  .checklist-item {
    position: relative;
    padding-left: 2.5rem;
    padding-bottom: 1rem;

    &.no {
      &:before {
        content: "";
        width: 24px;
        height: 24px;
        background-image: url("../images/checklist-x.svg");
        background-size: 14px;
        background-position: center center;
        background-repeat: no-repeat;
        display: inline-block;
        background-color: $forest5;
        border-radius: 12px;
        position: absolute;
        top: 2px;
        left: 0;
      }
    }
    &.yes {
      &:before {
        content: "";
        width: 24px;
        height: 24px;
        background-image: url("../images/checklist-yes.svg");
        background-size: 14px;
        background-position: center center;
        background-repeat: no-repeat;
        display: inline-block;
        background-color: $forest5;
        border-radius: 12px;
        position: absolute;
        top: 2px;
        left: 0;
      }
    }
  }
}

@include breakpoint(small only) {
  .mobile-text-left {
    text-align: left;
  }
}

p {
  a {
    &:not(.button) {
      transition: color 0.3s ease;
      color: $forest2;
      text-decoration: underline;
      &:hover {
        color: $forest1;
      }
    }
  }
  .has-white-color & {
    a {
      &:not(.button) {
        color: $white;
        &:hover {
          color: $forest3;
        }
      }
    }
  }
}
h1,
h2 {
  font-family: $besley;
  font-weight: 500 !important;
  @include breakpoint(small only) {
    font-weight: 500 !important;
  }
  .bucket__tag {
    font-family: $mulish;
  }
}

.as-h4,
.as-h5 {
  &.is-fancy {
    font-family: $besley;
    font-weight: 500 !important;
    @include breakpoint(small only) {
      font-weight: 500 !important;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.as-h1,
.as-h2,
.as-h3,
.as-h4,
.as-h5 {
  color: $coal2;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  &.is-fancy {
    font-family: $besley;
    font-weight: 400;
  }
  &.as-tag {
    text-transform: uppercase;
    font-weight: 700;
  }
}

.has-color.has-white-color {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  p a:not(.button),
  .as-h1,
  .as-h2,
  .as-h3,
  .as-h4,
  .as-h5 {
    color: $white;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
div,
span,
a:not(.button) {
  // color: inherit;
  &.as-h1 {
    font-size: 64px;
    line-height: 72px;
    @include breakpoint(small only) {
      font-size: 32px;
      line-height: 48px;
    }
  }
  &.as-h2 {
    font-size: 40px;
    line-height: 56px;
    @include breakpoint(small only) {
      font-size: 26px;
      line-height: 36px;
    }
  }
  &.as-h3 {
    font-size: 32px;
    line-height: 44px;
    @include breakpoint(small only) {
      font-size: 24px;
      line-height: 32px;
    }
  }
  &.as-h4 {
    font-size: 24px;
    line-height: 32px;
    @include breakpoint(small only) {
      font-size: 20px;
      line-height: 28px;
    }
  }
  &.as-h5 {
    font-size: 20px;
    line-height: 28px;
    @include breakpoint(small only) {
      font-size: 16px;
      line-height: 24px;
    }
  }
  &.as-h6 {
    font-size: 16px;
    line-height: 24px;
  }

  .has-text-color & {
    color: inherit;
  }
}

body {
  font-size: 18px;
  line-height: 28px;
  p {
    font-weight: 400;
  }
}
// p {
//   font-size: 18px;
//   line-height: 28px;
//   &.text-20 {
//     font-size: 20px;
//     line-height: 28px;
//   }
// }
.button-group a {
  font-size: 18px;
  line-height: 16px;
  &.text-20 {
    font-size: 20px;
    line-height: 18px;
  }
}

.button-group {
  margin-top: 1.5rem;
}

.rounded-24 {
  border-radius: 24px;
}
.rounded-8 {
  border-radius: 8px;
}

.overflow-hidden {
  overflow: hidden;
}

// dynamic pill

.dynamic__pill {
  display: flex;
  align-items: center;
  background-color: $berry4;
  padding: 1.275em;
  border-radius: 42px;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  .dynamic__pill-text {
    margin-right: 1.275em;
  }
}

// .circle__animation {
//   width: 24px;
//   height: 24px;
//   margin: 0;
//   position: relative;
//   transform: rotate(90deg);
//   &:before {
//     content: "";
//     width: 18px;
//     height: 18px;
//     display: inline-block;
//     background-color: $coal3;
//     border: 4px solid $berry4;
//     position: absolute;
//     top: 3px;
//     right: 3px;
//     bottom: 3px;
//     left: 3px;
//     z-index: 4;
//     border-radius: 11px;
//     box-sizing: border-box;
//   }
// }

// #loading {
//   width: 24px;
//   height: 24px;
//   margin: 30px auto;
//   position: relative;
// }

// .hold {
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   clip: rect(0px, 24px, 24px, 12px);
//   border-radius: 100%;
//   background-color: transparent;
// }
// .fill {
//   background-color: #cad6df;
// }
// .fill {
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   border-radius: 100%;
//   clip: rect(0px, 12px, 24px, 0px);
// }
// .left .fill {
//   z-index: 1;
//   animation: left 6s linear both infinite;
// }
// @keyframes left {
//   0% {
//     transform: rotate(0deg);
//   }
//   25% {
//     transform: rotate(180deg);
//   }
//   50% {
//     transform: rotate(180deg);
//   }
//   75% {
//     transform: rotate(360deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }

// .right {
//   z-index: 3;
//   transform: rotate(180deg);
// }
// .right .fill {
//   z-index: 3;
//   animation: right 6s linear both infinite;
//   // animation-delay: 1s;
// }
// @keyframes right {
//   0% {
//     transform: rotate(0deg);
//   }
//   25% {
//     transform: rotate(0deg);
//   }
//   50% {
//     transform: rotate(180deg);
//   }
//   75% {
//     transform: rotate(180deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }

// for dev only

.palette__sandbox {
  .sandbox__palette-tile {
    padding: 0.625em 1.25em 0.625em 4.25em;
    display: inline-block;
    margin: 0.625em;
    border-radius: 6px;
    position: relative;
    width: calc(20% - 1.5em);
    span {
      position: relative;
      z-index: 2;
    }
    &:before {
      content: "";
      background-color: inherit;
      width: 3em;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      display: inline-block;
      z-index: 3;
    }
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: $white;
      z-index: 1;
      border: 1px solid $coal5;
    }
  }
}
// accessibility

a.skip-to-content {
  position: absolute;
  top: -1000px;
  left: -1000px;
  height: 1px;
  width: 1px;
  text-align: left;
  overflow: hidden;
  background-color: $coal1;
  padding: 5px 10px;
  z-index: 10000;
  color: $white;
  height: auto !important;
  margin-left: 5px;
  &:active,
  &:focus,
  &:hover {
    left: 0;
    top: 0;
    width: auto;
    height: auto;
    overflow: visible;
  }
}

.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

/*********************
POSTS & CONTENT STYLES
*********************/

.page-title {
  // Remove Foundation vcard styling from author page titles
  .vcard {
    border: 0px;
    padding: 0px;
  }
}

// Post meta
.byline {
  color: #999;
  time {
  }
}

// Entry content
.entry-content {
  img {
    max-width: 100%;
    height: auto;
  }

  .alignleft,
  img.alignleft {
    margin-right: 1.5em;
    display: inline;
    float: left;
  }
  .alignright,
  img.alignright {
    margin-left: 1.5em;
    display: inline;
    float: right;
  }
  .aligncenter,
  img.aligncenter {
    margin-right: auto;
    margin-left: auto;
    display: block;
    clear: both;
  }

  video,
  object {
    max-width: 100%;
    height: auto;
  }

  pre {
    background: #eee;
    border: 1px solid #cecece;
    padding: 10px;
  }
}

// Add Foundation styling to password protect button
.post-password-form input[type="submit"] {
  @include button;
}

/*********************
IMAGE GALLERY STYLES
*********************/
.gallery {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-right: -15px;
  margin-left: -15px;
}
.gallery::before,
.gallery::after {
  content: " ";
  display: table;
}
.gallery::after {
  clear: both;
}
.gallery.gallery-columns-1 > dl {
  @include grid-column;
}
.gallery.gallery-columns-2 > dl {
  @include grid-column(1 of 2);
}
.gallery.gallery-columns-3 > dl {
  @include grid-column(1 of 3);
}
.gallery.gallery-columns-4 > dl {
  @include grid-column(1 of 4);
}
.gallery.gallery-columns-5 > dl {
  @include grid-column(1 of 5);
}
.gallery.gallery-columns-6 > dl {
  @include grid-column(1 of 6);
}
.gallery.gallery-columns-7 > dl {
  @include grid-column(1 of 7);
}
.gallery.gallery-columns-8 > dl {
  @include grid-column(1 of 8);
}
.gallery dl {
  width: 100%;
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}
.gallery dl:last-child:not(:first-child) {
  float: right;
}

// Gallery caption styles
.gallery-caption {
}
.size-full {
}
.size-large {
}
.size-medium {
}
.size-thumbnail {
}

/*********************
PAGE NAVI STYLES
*********************/

.page-navigation {
  margin-top: 1rem;
}
@include breakpoint(small only) {
  .title-bar {
    z-index: 2;
    position: relative;
  }

  #top-bar-menu {
    position: fixed;
    overflow-x: hidden;
    left: 0;
    right: 0;
    width: 100%;
  }
}

.top-bar-right {
  max-width: calc(100% - 155px);
  width: 100%;
}

/*********************
COMMENT STYLES
*********************/
#comments {
  .commentlist {
    margin-left: 0px;
  }
}

#respond {
  ul {
    margin-left: 0px;
  }
}

.commentlist {
  li {
    position: relative;
    clear: both;
    overflow: hidden;
    list-style-type: none;
    margin-bottom: 1.5em;
    padding: 0.7335em 10px;

    &:last-child {
      margin-bottom: 0;
    }

    ul.children {
      margin: 0;

      li {
      }

      .comment {
      }
      .alt {
      }
      .odd {
      }
      .even {
      }
      .depth-1 {
      } // Change number for different depth
      .byuser {
      }
      .bypostauthor {
      }
      .comment-author-admin {
      }
    }
  }

  li[class*="depth-"] {
    margin-top: 1.1em;
  }

  li.depth-1 {
    margin-left: 0;
    margin-top: 0;
  }

  li:not(.depth-1) {
    margin-left: 10px;
    margin-top: 0;
    padding-bottom: 0;
  }

  li.depth-2 {
  }
  li.depth-3 {
  }
  li.depth-4 {
  }
  li.depth-5 {
  }

  // General comment classes
  .alt {
  }
  .odd {
  }
  .even {
  }
  .parent {
  }
  .comment {
  }
  .children {
  }
  .pingback {
  }
  .bypostauthor {
  }
  .comment-author {
  }
  .comment-author-admin {
  }
  .thread-alt {
  }
  .thread-odd {
  }
  .thread-even {
  }

  // vcard
  .vcard {
    margin-left: 50px;

    cite.fn {
      font-weight: 700;
      font-style: normal;

      a.url {
      }
    }

    time {
      float: right;

      a {
        color: #999;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    img.photo {
    }

    img.avatar {
      position: absolute;
      left: 10px;
      padding: 2px;
      border: 1px solid #cecece;
      background: #fff;
    }
  } // end .commentlist .vcard

  // comment meta
  .comment-meta {
    a {
    }
  }

  .commentmetadata {
    a {
    }
  }

  // comment content
  .comment_content {
    p {
      margin: 0.7335em 0 1.5em;
      font-size: 1em;
      line-height: 1.5em;
    }
  } // end .commentlist .comment_content

  // comment reply link
  .comment-reply-link {
    float: right;

    &:hover,
    &:focus {
    }
  } // end .commentlist .comment-reply-link
} // end .commentlist

/*********************
COMMENT FORM STYLES
*********************/

.reveal-overlay {
  padding: 0 1rem;
}
.reveal .close-button {
  color: $forest2;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 1.5rem;
  top: 1.5rem;
  font-size: 28px;
  font-weight: 600;
  border-radius: 40px;
  width: 40px;
  height: 40px;
  box-shadow: 0px 4px 8px rgba(147, 147, 147, 0.1);
  transition: box-shadow 0.3s ease;
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  span {
    transform: translateY(-2px);
  }
}
.respond-form {
  margin: 1.5em 10px;
  padding-bottom: 2.2em;

  form {
    margin: 0.75em 0;

    li {
      list-style-type: none;
      clear: both;
      margin-bottom: 0.7335em;

      label,
      small {
        display: none;
      }
    }

    input[type="text"],
    input[type="email"],
    input[type="url"],
    textarea {
      padding: 3px 6px;
      background: #efefef;
      border: 2px solid #cecece;
      line-height: 1.5em;

      &:focus {
        background: #fff;
      }

      // form validation
      &:invalid {
        outline: none;
        border-color: #fbc2c4;
        background-color: #f6e7eb;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        -ms-box-shadow: none;
        box-shadow: none;
      }
    }

    input[type="text"],
    input[type="email"],
    input[type="url"] {
      max-width: 400px;
      min-width: 250px;
    }

    textarea {
      resize: none;
      width: 97.3%;
      height: 150px;
    }
  }
}

// comment form title
#comment-form-title {
  margin: 0 0 1.1em;
}

// cancel comment reply link
#cancel-comment-reply {
  a {
  }
}

// logged in comments
.comments-logged-in-as {
}

// allowed tags
#allowed_tags {
  margin: 1.5em 10px 0.7335em 0;
}

// No comments
.nocomments {
  margin: 0 20px 1.1em;
}

/*********************
SIDEBARS & ASIDES
*********************/
.sidebar {
  li {
    list-style: none;
  }
}

.widget {
  ul {
    margin: 0;
  }
}
/*********************
FOOTER STYLES
*********************/

.footer {
  clear: both;
  margin-top: 1em;
}

/*********************
VISUAL EDITOR STYLES
*********************/
// Makes content look better in visual editor
body#tinymce {
  margin: 20px;
}

/*********************
PLUGIN STYLES
*********************/
// Makes Gravity Forms play nice with Foundation Form styles
.gform_body ul {
  list-style: none outside none;
  margin: 0;
}

// Hide Gravity Forms validation fields when GFCSS is disabled
.gform_validation_container,
.gform_wrapper .gform_validation_container,
body .gform_wrapper li.gform_validation_container,
body .gform_wrapper .gform_body ul.gform_fields li.gfield.gform_validation_container,
body .gform_wrapper ul.gform_fields li.gfield.gform_validation_container {
  display: none !important;
  position: absolute !important;
  left: -9000px;
}

/*********************
Foundations Tab Modifiers
*********************/

.tabs {
  &.tabs__pills {
    @include breakpoint(small down) {
      display: column;
      align-items: center;
      flex-direction: column;
      .tabs-title {
        margin: 0.5rem auto;
      }
    }
    .tabs-title {
      a {
        transition: all 0.3s ease;
        background-color: $white;
        color: $coal2;
        padding: 0.5rem 1rem;
        border-radius: 2rem;
        margin: 0 0.5rem;
        border: 1px solid $coal5;
        display: inline-block;
        width: auto;
        font-size: 18px;
        line-height: 28px;
        &:hover {
          border: 1px solid $coal2;
        }
      }
      &.is-current,
      &.is-active {
        a {
          background-color: $coal2;
          color: $white;
          border: 1px solid $coal2;
        }
      }
    }
  }
}
.tabs-content {
  &:not(.no-border) {
    margin-top: 1.5rem;
    border-radius: 24px;
    border: 1px solid $coal4;
  }
}

.copy__container {
  position: relative;
  &:not(.social__post-content) {
    &:before {
      transition: opacity 0.3s ease;
      content: url("../images/icons/check-solid-white.svg");
      width: 40px;
      height: 40px;
      font-size: 20px;
      text-align: center;
      display: inline-block;
      color: $white;
      background-color: $forest2;
      border-radius: 20px;
      position: absolute;
      padding: 0.6rem;
      top: auto;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      z-index: 2;
      opacity: 0;
    }
  }

  &.social__post-content {
    .social__post-footer {
      a {
        font-weight: 600;
      }
    }
  }

  p {
    transition: opacity 0.3s ease;
    position: relative;
    z-index: 1;
  }
  &.copied {
    p {
      opacity: 0.5;
    }
    &:before {
      opacity: 1;
    }
  }
}

.dropdown__list-container {
  position: relative;
  min-width: 250px;
  display: inline-block;
  height: 44px;
  z-index: 9;
  cursor: pointer;
  svg {
    font-size: 9pt;
    color: #1b533f;
    font-weight: 600;
    position: absolute;
    right: 1pc;
    top: 1pc;
    pointer-events: none;
    z-index: 3;
  }

  .dropdown__list {
    transition: max-height 0.3s ease, border-color 0.3s ease;
    border: 1px solid #c8d0d8;
    border-radius: 4px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    background-color: #fff;
    max-height: 44px;
    overflow: hidden;
    padding-top: 44px;
    margin-left: 0;
    &:hover,
    &:focus,
    &:focus-within {
      border-color: #1b533f;
      max-height: 750pt;
      z-index: 2;
    }
    .dropdown__list-item {
      transition: color 0.3s ease;
      display: inline-block;
      width: 100%;
      height: 44px;
      padding: 5px 2pc 5px 9pt;
      background-color: #fff;
      color: #58728c;
      font-size: 1pc;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      a {
        display: block;
      }
      &.is-active {
        position: absolute;
        top: 0;
        left: 0;
        height: 45px;
        border-bottom: 1px solid #f6f9fc;
      }
    }
  }
}

.element__section--titles {
  p {
    font-size: 18px;
    line-height: 28px;
    margin-top: 2rem;
    @include breakpoint(small only) {
      &:nth-child(2) {
        margin-top: 1.5em;
      }
      &:last-child {
        margin-bottom: 0.5rem;
      }
    }
  }
}

// dropdown modifier
.dropdown-pane {
  box-shadow: $popovershadow;
  a {
    transition: color 0.3s ease;
    padding: 0.5rem 0;
    color: $coal2;
    &:hover {
      color: $forest2;
    }
  }
}

body#us {
  .ca-only {
    display: none;
  }
}

body#ca {
  .us-only {
    display: none;
  }
}

.additional_text {
  margin-top: 2rem;
}

.round-top {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 24px 24px 0 0;
  overflow: hidden;
  @include breakpoint(small only) {
    padding: 4rem 1.25rem;
    margin-left: 1rem;
    margin-right: 1rem;
    border-radius: 16px 16px 0 0;
  }
}
.round-bottom {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 0 0 24px 24px;
  overflow: hidden;
  @include breakpoint(small only) {
    padding: 4rem 1.25rem;
    margin-left: 1rem;
    margin-right: 1rem;
    border-radius: 0 0 16px 16px;
  }
}

.extended-container {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 50px;
  overflow: hidden;

  @include breakpoint(small only) {
    padding: 4rem 1.25rem;
    margin-left: 1rem;
    margin-right: 1rem;
    border-radius: 24px;
  }
  &.has-background {
    border-radius: 24px;
    @include breakpoint(small only) {
      border-radius: 16px;
    }
  }
}

iframe.video__iframe {
  width: 100%;
  height: 505px;

  @include breakpoint(medium only) {
    height: 400px;
  }
  @include breakpoint(small only) {
    height: 290px;
  }
}

.arrow__left,
.arrow__right {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  backdrop-filter: blur(2px);
  background-color: rgba(46, 58, 71, 0.1);
  transition: background-color 0.3s ease;
  cursor: pointer;

  &:hover {
    background-color: rgba(46, 58, 71, 0.25);
  }
}

body:has(#us-only-messaging),
body:has(#ca-only-messaging) {
  @include breakpoint(medium) {
    .component__hero-wrapper {
      &.has-announcement {
        padding-top: 0;
      }
    }
  }
}

body#ca:has(#us-only-messaging) {
  @include breakpoint(small only) {
    .component__hero {
      .grid-container {
        margin-top: 0;
      }
    }
  }
}

body#us:has(#ca-only-messaging) {
  @include breakpoint(small only) {
    .component__hero {
      .grid-container {
        margin-top: 0;
      }
    }
  }
}

.svg-inline--fa {
  max-height: 1em;
  height: 1em;
  vertical-align: -0.125em;
}

body:has(.no-nav) {
  .announcement__banner {
    top: 16px;
  }
}
.announcement__banner {
  height: 81px;
  @include breakpoint(medium down) {
    height: 157px;
  }
  @include breakpoint(small only) {
    height: 200px;
  }
  &:not(.in-content) {
    position: relative;
    top: 98px;
    opacity: 0;
    transform: translateY(45px);
    transition: all 0.3s ease;
    z-index: 4;

    &.visible {
      opacity: 1;
      transform: translateY(0);
    }
    .button.show-for-large {
      margin-bottom: 3px;
    }
    .callout {
      position: absolute;
      width: calc(100% - 2rem);
      left: 1rem;
      margin-bottom: 0;
    }
  }
  &.in-content {
    .announcement__content {
      flex-flow: column;
      align-items: self-start !important;
      .icon {
        margin-bottom: 1rem;
      }
      p {
        margin-bottom: 1rem !important;
      }
      a {
        text-align: left;
      }
    }
  }

  .callout {
    color: $grey700;
    border-radius: 16px;
    border: none;
    padding: 1.625rem 2rem;
    @include breakpoint(medium only) {
      padding: 1.5rem;
    }
    @include breakpoint(small only) {
      padding: 1rem;
    }
    &.has-shadow {
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
    }
    .announcement__content {
      display: flex;
      align-items: center;
      .icon {
        margin-right: 0.75rem;
        svg {
          height: 24px;
        }
      }
      p {
        margin-right: 1rem;
        margin-bottom: 0;
        a {
          margin-left: 1rem;
        }
      }
      a {
        padding: 0 !important;
      }
      @include breakpoint(medium down) {
        flex-flow: column;
        align-items: self-start;
        .icon {
          margin-bottom: 1rem;
        }
        p {
          margin-bottom: 1rem;
        }
        a {
          text-align: left;
        }
      }
    }
    .close-button {
      top: 1.5rem;
      right: 2rem;
      font-size: 1.75rem;
      color: $grey700;
      transition: all 0.3s ease;
      @include breakpoint(medium down) {
        right: 1rem;
        top: 0.75rem;
      }
      &:hover {
        color: $grey800;
      }
    }
  }
  .announcement__content {
    img {
      height: 24px;
    }
  }
}

.nav__experiment {
  position: fixed;
  display: flex;
  bottom: 0;
  align-items: self-end;
  flex-flow: column;
  justify-content: flex-end;
  width: 100%;
  padding: 0.7rem 1rem;
  z-index: 3;
  background: $white;
  box-shadow: 0px -4px 8px 0px rgba(147, 147, 147, 0.1);
  transition: all 0.3s ease;
  transform: translateY(100px);

  &.in-view {
    transform: translateY(0);
  }
}

body:has(.nav__experiment) {
  @include breakpoint(small only) {
    footer {
      margin-bottom: 5rem;
    }
    header:not(.legacy-header) .title-bar .title-bar-left a {
      position: relative;
    }
    #ada-button-frame {
      bottom: 68px !important;
    }
  }
}
