@import "../settings";

// transition one at 42%
// transition two at 84%

$animationLength: 15s;

.home__animation-container {
  @include breakpoint(medium only) {
    height: 460px;
  }
}
#home-hero {
  .home__video-button {
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    position: absolute;
    top: auto;
    bottom: 0;
    padding: 1rem 1rem 1rem 0.5rem;
    height: 59px;
    align-items: center;
    display: flex;
    font-weight: 800;
    color: $coal2;
    font-size: 18px;
    line-height: 16px;
    box-shadow: $shadowlight20;
    border-radius: 30px;
    background-color: rgba($white, 0.3);
    cursor: pointer;
    @include breakpoint(small only) {
      position: relative;
      margin: 4rem auto 0;
    }
    @include breakpoint(medium only) {
      width: 186px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
    @include breakpoint(medium down) {
      bottom: 0rem;
    }
    @include breakpoint(large) {
      left: 100px;
    }

    &:hover {
      background-color: $white;
      box-shadow: $shadowlight40;
    }
    &:before {
      content: "";
      background-image: url("../images/icons/play-icon.svg");
      background-repeat: no-repeat;
      background-position: 11px;
      background-size: 16px;
      width: 36px;
      height: 36px;
      background-color: $white;
      display: inline-block;
      border-radius: 18px;
      margin-right: 1rem;
    }
  }
  backface-visibility: hidden;
  // cylce between 2 images

  &.load {
    @keyframes loadIn {
      0% {
        opacity: 0;
        margin-bottom: -20px;
      }
      100% {
        opacity: 1;
        margin-bottom: 0;
      }
    }

    .animation__object {
      &.animation__object--1 {
        @include breakpoint(small only) {
          display: none;
        }
        @include breakpoint(medium) {
          transform: rotate(200deg) translateX(175px) rotate(-200deg);
          animation: loadIn 1s ease forwards 0.5s !important;
          opacity: 0;
        }
        @media (min-width: 1150px) {
          transform: rotate(200deg) translateX(210px) rotate(-200deg);
        }
      }
      &.animation__object--2 {
        @include breakpoint(small only) {
          display: none;
        }
        @include breakpoint(medium) {
          transform: rotate(30deg) translateX(175px) rotate(-30deg);
          animation: loadIn 1s ease forwards 1.1s !important;
          opacity: 0;
        }
        @media (min-width: 1150px) {
          transform: rotate(30deg) translateX(245px) rotate(-30deg);
        }
      }
      &.animation__object--3 {
        @include breakpoint(medium) {
          transform: rotate(-30deg) translateX(200px) rotate(30deg);
          animation: loadIn 1s ease forwards 0.8s !important;
          opacity: 0;
        }
        @media (min-width: 1150px) {
          transform: rotate(-30deg) translateX(245px) rotate(30deg);
        }
      }
    }
  }

  //animations only after load is gone

  @keyframes backgroundChange {
    0% {
      opacity: 0;
    }
    40% {
      opacity: 0;
    }
    44% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    94% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  // rotate card 1s search label 1

  @include breakpoint(medium) {
    @keyframes cardOneRotate {
      0% {
        transform: rotate(200deg) translateX(175px) rotate(-200deg);
      }
      38% {
        transform: rotate(200deg) translateX(175px) rotate(-200deg);
      }
      46% {
        transform: rotate(190deg) translateX(175px) rotate(-190deg);
      }
      86% {
        transform: rotate(190deg) translateX(175px) rotate(-190deg);
      }
      94% {
        transform: rotate(200deg) translateX(175px) rotate(-200deg);
      }
      100% {
        transform: rotate(200deg) translateX(175px) rotate(-200deg);
      }
    }
  }
  @media (min-width: 1150px) {
    @keyframes cardOneRotate {
      0% {
        transform: rotate(200deg) translateX(210px) rotate(-200deg);
      }
      38% {
        transform: rotate(200deg) translateX(210px) rotate(-200deg);
      }
      46% {
        transform: rotate(190deg) translateX(210px) rotate(-190deg);
      }
      86% {
        transform: rotate(190deg) translateX(210px) rotate(-190deg);
      }
      94% {
        transform: rotate(200deg) translateX(210px) rotate(-200deg);
      }
      100% {
        transform: rotate(200deg) translateX(210px) rotate(-200deg);
      }
    }
  }

  @keyframes cardOneResize {
    0% {
      width: 162px;
      height: 96px;
    }
    38% {
      width: 162px;
      height: 96px;
    }
    46% {
      width: 145px;
      height: 161.12px;
    }
    86% {
      width: 145px;
      height: 161.12px;
    }
    94% {
      width: 162px;
      height: 96px;
    }
    100% {
      width: 162px;
      height: 96px;
    }
  }

  @keyframes cardOneInner1Icon {
    0% {
      transform: translateX(0);
      opacity: 1;
    }
    38% {
      transform: translateX(0);
      opacity: 1;
    }
    46% {
      transform: translateX(-100px);
      opacity: 0;
    }
    86% {
      transform: translateX(-100px);
      opacity: 0;
    }
    92% {
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes cardOneInner1Text {
    0% {
      transform: translateX(0);
      opacity: 1;
    }
    38% {
      transform: translateX(0);
      opacity: 1;
    }
    46% {
      transform: translateX(100px);
      opacity: 0;
    }
    86% {
      transform: translateX(100px);
      opacity: 0;
    }
    94% {
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes cardOneInner2Icon {
    0% {
      transform: translateY(-100px);
      opacity: 0;
    }
    38% {
      transform: translateY(-100px);
      opacity: 0;
    }
    46% {
      transform: translateY(0);
      opacity: 1;
    }
    86% {
      transform: translateY(0);
      opacity: 1;
    }
    94% {
      transform: translateY(-100px);
      opacity: 0;
    }
    100% {
      transform: translateY(-100px);
      opacity: 0;
    }
  }

  @keyframes cardOneInner2Text {
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    38% {
      transform: translateY(100px);
      opacity: 0;
    }
    46% {
      transform: translateY(0);
      opacity: 1;
    }
    86% {
      transform: translateY(0);
      opacity: 1;
    }
    94% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(100px);
      opacity: 0;
    }
  }

  @keyframes cardOneInner2Lines {
    0% {
      transform: translateY(120px);
      opacity: 0;
    }
    39% {
      transform: translateY(120px);
      opacity: 0;
    }
    46% {
      transform: translateY(0);
      opacity: 1;
    }
    86% {
      transform: translateY(0);
      opacity: 1;
    }
    93% {
      transform: translateY(120px);
      opacity: 0;
    }
    100% {
      transform: translateY(120px);
      opacity: 0;
    }
  }

  @include breakpoint(medium) {
    @keyframes cardTwoRotate {
      0% {
        transform: rotate(40deg) translateX(175px) rotate(-40deg);
      }
      42% {
        transform: rotate(40deg) translateX(175px) rotate(-40deg);
      }
      50% {
        transform: rotate(30deg) translateX(175px) rotate(-30deg);
      }
      90% {
        transform: rotate(30deg) translateX(175px) rotate(-30deg);
      }
      98% {
        transform: rotate(40deg) translateX(175px) rotate(-40deg);
      }
      100% {
        transform: rotate(40deg) translateX(175px) rotate(-40deg);
      }
    }
  }
  @media (min-width: 1150px) {
    @keyframes cardTwoRotate {
      0% {
        transform: rotate(40deg) translateX(215px) rotate(-40deg);
      }
      42% {
        transform: rotate(40deg) translateX(215px) rotate(-40deg);
      }
      50% {
        transform: rotate(30deg) translateX(215px) rotate(-30deg);
      }
      90% {
        transform: rotate(30deg) translateX(215px) rotate(-30deg);
      }
      98% {
        transform: rotate(40deg) translateX(215px) rotate(-40deg);
      }
      100% {
        transform: rotate(40deg) translateX(215px) rotate(-40deg);
      }
    }
  }

  @keyframes cardTwoResize {
    0% {
      width: 218px;
      height: 80px;
    }
    42% {
      width: 218px;
      height: 80px;
    }
    50% {
      width: 183px;
      height: 185px;
    }
    90% {
      width: 183px;
      height: 185px;
    }
    98% {
      width: 218px;
      height: 80px;
    }
    100% {
      width: 218px;
      height: 80px;
    }
  }

  @keyframes cardTwoInner1Icon {
    0% {
      transform: translateX(0);
      opacity: 1;
    }
    42% {
      transform: translateX(0);
      opacity: 1;
    }
    50% {
      transform: translateX(-100px);
      opacity: 0;
    }
    90% {
      transform: translateX(-100px);
      opacity: 0;
    }
    98% {
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes cardTwoInner1Text {
    0% {
      transform: translateX(0);
      opacity: 1;
    }
    42% {
      transform: translateX(0);
      opacity: 1;
    }
    50% {
      transform: translateX(125px);
      opacity: 0;
    }
    90% {
      transform: translateX(125px);
      opacity: 0;
    }
    98% {
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes cardTwoInner2Icon {
    0% {
      transform: translateY(-100px);
      opacity: 0;
    }
    42% {
      transform: translateY(-100px);
      opacity: 0;
    }
    50% {
      transform: translateY(0);
      opacity: 1;
    }
    90% {
      transform: translateY(0);
      opacity: 1;
    }
    98% {
      transform: translateY(-100px);
      opacity: 0;
    }
    100% {
      transform: translateY(-100px);
      opacity: 0;
    }
  }

  @keyframes cardTwoInner2Lines {
    0% {
      transform: translateY(120px);
      opacity: 0;
    }
    43% {
      transform: translateY(120px);
      opacity: 0;
    }
    50% {
      transform: translateY(0);
      opacity: 1;
    }
    89% {
      transform: translateY(0);
      opacity: 1;
    }
    98% {
      transform: translateY(120px);
      opacity: 0;
    }
    100% {
      transform: translateY(120px);
      opacity: 0;
    }
  }

  @keyframes cardTwoInner2Text {
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    42% {
      transform: translateY(100px);
      opacity: 0;
    }
    50% {
      transform: translateY(0);
      opacity: 1;
    }
    90% {
      transform: translateY(0);
      opacity: 1;
    }
    98% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(100px);
      opacity: 0;
    }
  }
  @include breakpoint(medium) {
    @keyframes cardThreeRotate {
      0% {
        transform: rotate(-30deg) translateX(200px) rotate(30deg);
      }
      40% {
        transform: rotate(-30deg) translateX(200px) rotate(30deg);
      }
      48% {
        transform: rotate(-50deg) translateX(200px) rotate(50deg);
      }
      88% {
        transform: rotate(-50deg) translateX(200px) rotate(50deg);
      }
      96% {
        transform: rotate(-30deg) translateX(200px) rotate(30deg);
      }
      100% {
        transform: rotate(-30deg) translateX(200px) rotate(30deg);
      }
    }
  }
  @media (min-width: 1150px) {
    @keyframes cardThreeRotate {
      0% {
        transform: rotate(-30deg) translateX(215px) rotate(30deg);
      }
      40% {
        transform: rotate(-30deg) translateX(215px) rotate(30deg);
      }
      48% {
        transform: rotate(-50deg) translateX(215px) rotate(50deg);
      }
      88% {
        transform: rotate(-50deg) translateX(215px) rotate(50deg);
      }
      96% {
        transform: rotate(-30deg) translateX(215px) rotate(30deg);
      }
      100% {
        transform: rotate(-30deg) translateX(215px) rotate(30deg);
      }
    }
  }
  @keyframes cardThreeResize {
    0% {
      width: 177px;
      height: 185px;
    }
    40% {
      width: 177px;
      height: 185px;
    }
    48% {
      width: 202.28px;
      height: 96.24px;
    }
    88% {
      width: 202.28px;
      height: 96.24px;
    }
    96% {
      width: 177px;
      height: 185px;
    }
    100% {
      width: 177px;
      height: 185px;
    }
  }

  @keyframes cardThreeInner1Icon {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    40% {
      transform: translateY(0);
      opacity: 1;
    }
    48% {
      transform: translateY(-100px);
      opacity: 0;
    }
    88% {
      transform: translateY(-100px);
      opacity: 0;
    }
    96% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes cardThreeInner2Icon {
    0% {
      transform: translateX(-100px);
      opacity: 0;
    }
    40% {
      transform: translateX(-100px);
      opacity: 0;
    }
    48% {
      transform: translateX(0);
      opacity: 1;
    }
    88% {
      transform: translateX(0);
      opacity: 1;
    }
    96% {
      transform: translateX(-100px);
      opacity: 0;
    }
    100% {
      transform: translateX(-100px);
      opacity: 0;
    }
  }

  @keyframes cardThreeInner2Text {
    0% {
      transform: translateX(125px);
      opacity: 0;
    }
    40% {
      transform: translateX(125px);
      opacity: 0;
    }
    48% {
      transform: translateX(0);
      opacity: 1;
    }
    88% {
      transform: translateX(0);
      opacity: 1;
    }
    96% {
      transform: translateX(125px);
      opacity: 0;
    }
    100% {
      transform: translateX(125px);
      opacity: 0;
    }
  }

  @keyframes cardThreeInner1Lines {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    39% {
      transform: translateY(0);
      opacity: 1;
    }
    48% {
      transform: translateY(120px);
      opacity: 0;
    }
    87% {
      transform: translateY(120px);
      opacity: 0;
    }
    96% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes cardThreeInner1Text {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    40% {
      transform: translateY(0);
      opacity: 1;
    }
    48% {
      transform: translateY(100px);
      opacity: 0;
    }
    88% {
      transform: translateY(100px);
      opacity: 0;
    }
    96% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  &:not(.load) {
    .circular__animation {
      .animation__image {
        .animation__image--2 {
          @include breakpoint(medium) {
            animation: backgroundChange $animationLength ease infinite; // start animation for rotating the image
          }
        }
      }
      .animation__object {
        &.animation__object--1 {
          // start animation for object 1
          animation: cardOneRotate $animationLength ease infinite,
            cardOneResize $animationLength ease infinite;
          @include breakpoint(small only) {
            display: none;
          }
          .animation__inner {
            &.animation__inner-1 {
              .animation__inner-icon {
                animation: cardOneInner1Icon $animationLength ease infinite; // start animation for object 1, inner 1 search
              }
              .animation__inner-text {
                animation: cardOneInner1Text $animationLength ease infinite; // start animation for object 1, inner 2 search
              }
            }
            &.animation__inner-2 {
              .animation__inner-icon {
                animation: cardOneInner2Icon $animationLength ease infinite; // start animation for object 1, inner 1 search
              }
              .animation__inner-text {
                animation: cardOneInner2Text $animationLength ease infinite; // start animation for object 1, inner 2 search
              }
              .animation__fake-text {
                animation: cardOneInner2Lines $animationLength ease infinite; // start animation for object 1, inner 2 search
              }
            }
          }
        }
        &.animation__object--2 {
          animation: cardTwoRotate $animationLength ease infinite,
            cardTwoResize $animationLength ease infinite; // start animation for object 2
          @include breakpoint(small only) {
            display: none;
          }
          .animation__inner-1 {
            .animation__inner-icon {
              animation: cardTwoInner1Icon $animationLength ease infinite; // start animation for object 2, inner 1 icon
            }
            .animation__inner-text {
              animation: cardTwoInner1Text $animationLength ease infinite; // start animation for object 2, inner 1 text
            }
          }
          .animation__inner-2 {
            .animation__inner-icon {
              animation: cardTwoInner2Icon $animationLength ease infinite; // start animation for object 2, inner 2 icon
            }
            .animation__inner-text {
              animation: cardTwoInner2Text $animationLength ease infinite; // start animation for object 2, inner 2 text
            }
            .animation__fake-text {
              animation: cardTwoInner2Lines $animationLength ease infinite; // start animation for object 2, inner 2 lines
            }
          }
        }
        &.animation__object--3 {
          @include breakpoint(medium) {
            animation: cardThreeRotate $animationLength ease infinite,
              cardThreeResize $animationLength ease infinite;

            .animation__inner-1 {
              .animation__inner-icon {
                animation: cardThreeInner1Icon $animationLength ease infinite; // start animation for object 1, inner 1 search
              }
              .animation__inner-text {
                animation: cardThreeInner1Text $animationLength ease infinite; // start animation for object 1, inner 2 search
              }
              .animation__fake-text {
                animation: cardThreeInner1Lines $animationLength ease infinite; // start animation for object 1, inner 2 search
              }
            }
            .animation__inner-2 {
              .animation__inner-icon {
                animation: cardThreeInner2Icon $animationLength ease infinite; // start animation for object 1, inner 1 search
              }
              .animation__inner-text {
                animation: cardThreeInner2Text $animationLength ease infinite; // start animation for object 1, inner 2 search
              }
            }
          }
        }
      }
    }
  }

  .circular__animation {
    margin: 3rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto 0 auto auto;

    @include breakpoint(medium down) {
      width: 100%;
      height: 300px;
      top: 20px;
      bottom: auto;
      margin: 2rem auto 0;
    }
    @media (min-width: 1024px) {
      right: 5.5rem;
    }
    @media (min-width: 1150px) {
      right: 7.5rem;
    }
    @include breakpoint(small only) {
      position: relative;
      width: 100%;
      top: 0;
      right: auto;
      left: 0;
      bottom: auto;
      max-width: 343px;
      margin: 0 auto;
      .animation__image {
        margin-right: auto;
        margin-bottom: auto;
      }
    }

    font-weight: 800;
    font-size: 12px;
    line-height: 1rem;
    color: $coal2;
    -webkit-font-smoothing: subpixel-antialiased;
    @include breakpoint(small only) {
      font-size: 10px;
      line-height: 16px;
    }
    @include breakpoint(large) {
      width: 310px;
      border-radius: 250px;
      height: 500px;
    }
    * {
      -webkit-font-smoothing: subpixel-antialiased;
    }
    // zoom: 1.003;

    .horizontal__icon {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .animation__inner-icon {
        width: 64px;
        height: 100%;
        display: inline-block;
      }
      .animation__inner-text {
        height: auto;
        display: inline-block;
      }
    }

    .vertical__icon {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .animation__image {
      display: inline-block;
      overflow: hidden;
      position: relative;
      @include breakpoint(small) {
        width: 250px;
        height: 250px;
        border-radius: 125px;
      }
      @media (min-width: 1150px) {
        width: 310px;
        height: 310px;
        border-radius: 155px;
      }

      .animation__image--1 {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: url("../images/homepage-person-1.webp");
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        z-index: 1;
        border-radius: 50%;
      }

      .animation__image--2 {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: url("../images/homepage-person-2.webp");
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        z-index: 2;
        opacity: 0;
      }
    }
    .animation__object {
      background: $berry5;
      box-shadow: $popovershadow;
      border-radius: 12px;
      padding: 16px;
      position: absolute;
      z-index: 3;
      overflow: hidden;
      @include breakpoint(small only) {
        padding: 12px;
      }
      &.animation__object--1 {
        // default size before animation
        width: 162px;
        height: 96px;

        @include breakpoint(small only) {
          display: none;
        }

        .animation__inner {
          position: absolute;
          top: 16px;
          bottom: 16px;
          width: calc(100% - 32px);
          margin: auto 0;
        }
        .animation__inner-1 {
          .animation__inner-text {
            width: 58px;
          }
        }
        .animation__inner-2 {
          .animation__inner-icon {
            transform: translateY(-100px);
          }
          .animation__inner-text {
            white-space: nowrap;
            margin-top: 0.5rem;
            transform: translateY(100px);
          }
          .animation__fake-text {
            width: 107px;
            height: 12px;
            border-radius: 6px;
            display: inline-block;
            background-color: #e4e9ed;
            margin-top: 0.5rem;
            text-align: center;
            transform: translateY(120px);
            &:after {
              content: "";
              width: 86px;
              height: 12px;
              border-radius: 6px;
              display: inline-block;
              background-color: #e4e9ed;
              transform: translateY(20px);
            }
          }
        }
      }
      &.animation__object--2 {
        width: 218px;
        height: 80px;
        @include breakpoint(small only) {
          top: 0;
          right: 0;
        }
        @include breakpoint(medium) {
          transform: rotate(40deg) translateX(175px) rotate(-40deg);
        }
        @media (min-width: 1150px) {
          transform: rotate(40deg) translateX(215px) rotate(-40deg);
        }
        overflow: hidden;

        .animation__inner {
          position: absolute;
          top: 16px;
          bottom: 16px;
          width: calc(100% - 32px);
          margin: auto 0;
        }
        .animation__inner-1 {
          .animation__inner-icon {
            margin-top: -8px;
          }
          .animation__inner-text {
            width: 105px;
            white-space: nowrap;
          }
        }
        .animation__inner-2 {
          .animation__inner-icon {
            transform: translateY(-100px);
            width: 64px;
            height: 64px;
          }
          .animation__inner-text {
            white-space: nowrap;
            margin-top: 0.5rem;
            transform: translateY(100px);
          }
          .animation__fake-text {
            width: 150px;
            height: 12px;
            border-radius: 6px;
            display: inline-block;
            background-color: #e4e9ed;
            margin-top: 0.5rem;
            transform: translateY(120px);
            &:after {
              content: "";
              width: 127px;
              height: 12px;
              border-radius: 6px;
              display: inline-block;
              background-color: #e4e9ed;
              transform: translateY(20px);
            }
          }
        }
      }
      &.animation__object--3 {
        width: 177px;
        height: 185px;
        overflow: hidden;
        @include breakpoint(small only) {
          bottom: 0;
          right: 0;
          width: 146px;
          height: 169px;
          transform: translateY(30px);
          .animation__inner-text {
            width: 100%;
          }
        }
        @include breakpoint(medium) {
          transform: rotate(-30deg) translateX(200px) rotate(30deg);
        }
        @media (min-width: 1150px) {
          transform: rotate(-30deg) translateX(215px) rotate(30deg);
        }
        .fake__text {
          width: 156px;
          height: 10px;
          border-radius: 5px;
          display: inline-block;
          background-color: #e4e9ed;
          &:after {
            content: "";
            width: 101px;
            height: 10px;
            border-radius: 5px;
            display: inline-block;
            background-color: #e4e9ed;
            transform: translateY(15px);
          }
        }
        .animation__inner {
          position: absolute;
          top: 16px;
          bottom: 16px;
          width: calc(100% - 32px);
          margin: auto 0;
        }
        .animation__inner-1 {
          .animation__inner-icon {
            width: 64px;
            height: 64px;
          }
          .animation__inner-text {
            white-space: nowrap;
            margin-top: 0.5rem;
          }
          .animation__fake-text {
            width: 150px;
            height: 12px;
            border-radius: 6px;
            display: inline-block;
            background-color: #e4e9ed;
            margin-top: 0.5rem;
            @include breakpoint(small only) {
              width: 122px;
              height: 12px;
            }
            &:after {
              content: "";
              width: 127px;
              height: 12px;
              border-radius: 6px;
              display: inline-block;
              background-color: #e4e9ed;
              transform: translateY(20px);
              @include breakpoint(small only) {
                width: 113px;
                height: 12px;
              }
            }
          }
        }
        .animation__inner-2 {
          .animation__inner-icon {
            transform: translateX(-100px);
          }
          .animation__inner-text {
            transform: translateX(120px);
            width: 91px;
            white-space: nowrap;
            position: relative;
            margin-top: -20px;
            &:after {
              content: "";
              width: 89px;
              height: 12px;
              border-radius: 6px;
              display: inline-block;
              background-color: #e4e9ed;
              position: absolute;
              bottom: -20px;
              left: 0;
            }
          }
        }
      }
    }
  }
}
