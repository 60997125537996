.share_buttons {
  ul {
    margin-left: 0px;
    display: flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    align-items: stretch;
    -webkit-align-items: stretch;

    li {
      flex: initial !important;
      display: inline-block;
      margin-right: 1.2rem !important;
      transition: flex 0.1s ease-in-out;

      a {
        transition: all 0.2s linear;
        float: left;

        &:hover {
          transform: translateY(-3px);
          -webkit-transform: translateY(-3px);
          box-shadow: 0 5px 20px rgba(0, 0, 0, 0.25) !important;
        }

        background: #36485c;
        border-radius: 50%;
        padding: 8px 2px;

        svg {
          width: 30px;
          height: 30px;
          fill: white;
        }
        span {
          display: none;
        }
      }
    }
  }
}
